import {
    Box,
    Divider,
    HStack,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    VStack,
} from '@chakra-ui/react';
import { Formik, Form, Field, FieldProps, FormikProps } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { SwapCoins } from '../../../assets/swap-coins';
import { CloseModalButton } from '../common';
import { IconBox } from '../common-reporting/progress-steps';
import {
    ChainSelector,
    ClaimAmountField,
    claimAmountValidationSchema,
    EmailField,
    RecipientField,
} from '../create-claim-modal/create-claim-inputs';
import { useOnboard, useWeb3 } from '../../../hooks/useWeb3';
import { ChainId, NETWORKS, SWAP_SUPPORTED_NETWORKS, TokenDto } from '../../../data-lib/networks';
import { CreateWhiteButton, OrangeButton } from '../../inputs/buttons';
import * as Yup from 'yup';
import { apply } from '../../../tools/common';
import { useUIState } from '../../../state/ui-state';
import { useAllowances } from '../../../hooks/useAllowances';
import { BigNumber } from 'ethers';
import { useTokenBalances } from '../../../hooks/useChainData';

const supportedSwapNetworks = [...SWAP_SUPPORTED_NETWORKS] as ChainId[];

type CreateSwapModalProps = { isOpen: boolean; onClose: VoidFunction };

const CancelButton = CreateWhiteButton('Cancel');

const errorSchema = Yup.object().shape({
    offerAmount: claimAmountValidationSchema.required('Offer amount is required'),
    receiveAmount: claimAmountValidationSchema.required('Receive amount is required'),
    offerTo: Yup.string().required('Recipient address is required'),
    emailAddress: Yup.string()
        .email('Invalid email address')
        .optional()
        .transform(value => (value === '' ? undefined : value)),
    offerToken: Yup.string().required('Offer token is required'),
    receiveToken: Yup.string().required('Receive token is required'),
});

interface SwapFormValues {
    offerAmount: string;
    offerToken: TokenDto;
    receiveAmount: string;
    receiveToken: TokenDto;
    offerTo: string;
    emailAddress?: string;
}

export const CreateSwapModal = ({ isOpen, onClose }: CreateSwapModalProps) => {
    const { connectedNetwork } = useWeb3();
    const [approvalPending, { getAllowanceForTokenAddress, approveTokens, approveModalOpen, closeApproveModal }] =
        useAllowances('exact-allowance');
    const [allowance, setAllowance] = React.useState<'init' | BigNumber>('init');
    const [executingSafeTx, setExecutingSafeTx] = useState<boolean>(false);
    const isLoading = approvalPending || executingSafeTx; // TODO: add allowance == 'init' ||  executing  from useBullaSwap hook
    const { changeNetwork } = useOnboard();
    const { transactionPending } = useUIState();
    const modalContentRef = useRef<HTMLDivElement>(null);
    const tokenBalances = useTokenBalances({ chainId: connectedNetwork, poll: true });

    const sortedTokens = useMemo(() => {
        const tokensWithBalance = Object.values(NETWORKS[connectedNetwork].supportedTokens).map(tokenInfo => {
            const balance = tokenBalances.getBalanceForToken(tokenInfo.token.address)?.toString() ?? '0';
            return { ...tokenInfo, balance };
        });

        tokensWithBalance.sort((a, b) => parseFloat(b.balance) - parseFloat(a.balance));

        return tokensWithBalance;
    }, [connectedNetwork, tokenBalances]);

    const defaultOfferToken = sortedTokens[0]?.token;
    const defaultReceiveToken = sortedTokens[1]?.token;

    const formikRef = useRef<FormikProps<SwapFormValues>>(null);

    useEffect(() => {
        if (formikRef.current && sortedTokens.length >= 2) {
            formikRef.current.setFieldValue('offerToken', sortedTokens[0]?.token);
            formikRef.current.setFieldValue('receiveToken', sortedTokens[1]?.token);
        }
    }, [connectedNetwork, sortedTokens]);

    return (
        <Modal
            isCentered
            isOpen={isOpen}
            onClose={onClose}
            motionPreset="slideInBottom"
            closeOnOverlayClick={true}
            closeOnEsc={true}
            size="2xl"
        >
            <ModalOverlay />
            <ModalContent ref={modalContentRef}>
                <Formik
                    innerRef={formikRef}
                    initialValues={{
                        offerAmount: '',
                        offerToken: defaultOfferToken,
                        receiveAmount: '',
                        receiveToken: defaultReceiveToken,
                        offerTo: '',
                        emailAddress: '',
                    }}
                    onSubmit={(values, actions) => {
                        return;
                    }}
                    validationSchema={errorSchema}
                >
                    {({ errors, touched, values, setFieldValue, setFieldTouched }) => {
                        return (
                            <Form placeholder={''}>
                                <ModalHeader display="flex" flexDirection="row">
                                    <HStack alignItems="center">
                                        <IconBox icon={SwapCoins} boxShadow="none" w="50px" h="50px" />
                                        <VStack align="flex-start" ml={3} spacing={0}>
                                            <Text color="heading" fontWeight={'700'} fontSize="20px">
                                                Swap Offer
                                            </Text>
                                            <Text fontSize={'15px'} fontWeight={'400'} color="gray.600">
                                                Swap coins with any wallet.
                                            </Text>
                                        </VStack>
                                    </HStack>
                                </ModalHeader>
                                <Box mt={4}>
                                    <Divider width="calc(100%)" sx={{ height: '0.5px' }} />
                                </Box>
                                <ModalBody>
                                    <Box>
                                        <Text fontSize="14px" fontWeight="500" mb="2">
                                            Chain
                                        </Text>
                                        <ChainSelector
                                            chainId={connectedNetwork}
                                            isDisabled={isLoading}
                                            selectableChains={supportedSwapNetworks}
                                            textAlign={'left'}
                                            onChainSelected={changeNetwork}
                                            w="100%"
                                            mb="4"
                                        />
                                    </Box>
                                    <VStack spacing="3">
                                        <Field name="offerAmount">
                                            {({ field }: FieldProps) => (
                                                <ClaimAmountField
                                                    {...{
                                                        claimType: 'Swap',
                                                        field,
                                                        isDisabled: transactionPending,
                                                        includeNativeToken: false,
                                                        error: errors.offerAmount,
                                                        touched: touched.offerAmount,
                                                        setAmount: apply(setFieldValue, 'offerAmount'),
                                                        setToken: apply(setFieldValue, 'offerToken'),
                                                        amount: values.offerAmount,
                                                        token: values.offerToken,
                                                        setFieldTouched,
                                                        label: 'Offer',
                                                    }}
                                                />
                                            )}
                                        </Field>
                                        <Field name="receiveAmount">
                                            {({ field }: FieldProps) => (
                                                <ClaimAmountField
                                                    {...{
                                                        claimType: 'Swap',
                                                        field,
                                                        isDisabled: transactionPending,
                                                        includeNativeToken: false,
                                                        error: errors.receiveAmount,
                                                        touched: touched.receiveAmount,
                                                        setAmount: apply(setFieldValue, 'receiveAmount'),
                                                        setToken: apply(setFieldValue, 'receiveToken'),
                                                        amount: values.receiveAmount,
                                                        token: values.receiveToken,
                                                        setFieldTouched,
                                                        label: 'Receive',
                                                    }}
                                                />
                                            )}
                                        </Field>
                                        <Field name="offerTo">
                                            {({ field }: FieldProps) => (
                                                <RecipientField
                                                    {...{
                                                        field,
                                                        isDisabled: transactionPending,
                                                        error: errors.offerTo,
                                                        touched: touched.offerTo,
                                                        setRecipient: name => !!name && setFieldValue('offerTo', name),
                                                        label: 'Offer to',
                                                        dropdownModalRef: modalContentRef,
                                                        initialValue: values.offerTo,
                                                        required: true,
                                                    }}
                                                />
                                            )}
                                        </Field>
                                    </VStack>
                                    <Divider sx={{ height: '0.5px' }} my="4" />
                                    <Field name="emailAddress">
                                        {({ field }: FieldProps) => (
                                            <EmailField
                                                {...{
                                                    field,
                                                    isDisabled: isLoading,
                                                    error: errors.emailAddress,
                                                    touched: touched.emailAddress,
                                                    label: 'Confirmation Email',
                                                    required: false,
                                                    mt: '6',
                                                }}
                                            />
                                        )}
                                    </Field>
                                </ModalBody>
                                <Box mt={4}>
                                    <Divider width="calc(100%)" sx={{ height: '0.5px' }} />
                                </Box>
                                <ModalFooter>
                                    <HStack w="100%" spacing={4}>
                                        <CancelButton onClick={onClose} h="12" w="50%" />
                                        <OrangeButton w="50%" onClick={() => null}>
                                            Approve
                                        </OrangeButton>
                                    </HStack>
                                </ModalFooter>
                            </Form>
                        );
                    }}
                </Formik>

                <CloseModalButton onClose={onClose} />
            </ModalContent>
        </Modal>
    );
};
