import { Provider, TransactionReceipt } from '@ethersproject/abstract-provider';
import { BaseProvider, Web3Provider } from '@ethersproject/providers';
//@ts-expect-error
import * as ethProvider from 'eth-provider';
import { constants, ethers, providers } from 'ethers';
import { IN_DEV_ENV, IN_STAGING_ENV, userIsViewingPage } from '../tools/common';
import { EthAddress } from './ethereum';

import EthereumLogo from 'url:../assets/ethereum-logo.png';
import CeloLogo from 'url:../assets/celo-logo.jpg';
import PolygonLogo from 'url:../assets/polygon-logo.png';
import ArbitrumLogo from 'url:../assets/arbitrum-logo.svg';
import AvalancheLogo from 'url:../assets/avalanche-logo.svg';
import SepoliaLogo from 'url:../assets/sepolia-logo.png';
import BaseLogo from 'url:../assets/base-logo.png';
import BNBLogo from 'url:../assets/bnb-logo.png';
import HarmonyLogo from 'url:../assets/harmony-logo.png';
import AuroraLogo from 'url:../assets/aurora-logo.png';
import MoonbeamLogo from 'url:../assets/moonbeam-logo.png';
import FuseLogo from 'url:../assets/fuse-logo.png';
import GnosisChainLogo from 'url:../assets/gnosis-chain-logo.png';
import OptimismLogo from 'url:../assets/optimism-logo.png';
import BullaLogo from 'url:../assets/logo_orange.svg';

export enum TokenVariant {
    USDC = 'USDC',
    USDT = 'USDT',
    BTC = 'BTC',
    ETH = 'ETH',
    EUR = 'EUR',
    ONE = 'ONE',
    MATIC = 'MATIC',
    DAI = 'DAI',
    SOV = 'SOV',
    BRZ = 'BRZ',
    STABLE = 'STABLE',
    AVAX = 'AVAX',
    FUSE = 'FUSE',
    GLMR = 'GLMR',
    CELO = 'CELO',
    UNKNOWN = 'UNKNOWN',
}

export class TokenDto {
    readonly address: string;
    readonly decimals: number;
    readonly symbol: string;
    readonly name: string | undefined;
    readonly isNative: boolean;

    constructor(_address: string, _decimals: number, _symbol: string, _name?: string) {
        this.address = _address;
        this.decimals = _decimals;
        this.symbol = _symbol;
        this.name = _name;
        this.isNative = _address === constants.AddressZero;
    }
}

export type TokenInfo = {
    token: TokenDto;
    icon: string;
    variant: TokenVariant;
    chainId: ChainId;
};

export type TokenList = { [tokenSymbol: string]: TokenInfo };

declare global {
    interface Window {
        provider: any;
        ethereum: any;
    }
    interface Navigator {
        brave?: {
            isBrave: {
                name: string;
            };
        };
    }
}

export const INFURA_API_KEY = '8fb974170b1743288e9e6fac3bed68a0';
export const GET_BLOCK_API_KEY = '8faa4b0c-365c-4c52-aca5-bebb1f5cbbeb';

export const chainIds = {
    MAINNET: 1,
    BASE: 8453,
    RSK: 30,
    MATIC: 137,
    GNOSIS: 100,
    LOCALHOST: 31337,
    HARMONY: 1666600000,
    AVALANCHE: 43114,
    CELO: 42220,
    AURORA: 1313161554,
    MOONBEAM: 1284,
    ARBITRUM: 42161,
    FUSE: 122,
    OPTIMISM: 10,
    BNB: 56,
    SEPOLIA: 11155111,
} as const;

export type ChainId = typeof chainIds[keyof typeof chainIds];

export const EXTERNAL_TRANSACTIONS_SUPPORTED_NETWORKS = [
    chainIds.MAINNET,
    chainIds.ARBITRUM,
    chainIds.MATIC,
    chainIds.AVALANCHE,
    chainIds.GNOSIS,
    chainIds.CELO,
    chainIds.MOONBEAM,
    chainIds.FUSE,
    chainIds.AURORA,
    chainIds.BNB,
    chainIds.BASE,
    chainIds.OPTIMISM,
] as const;

export const GNOSIS_CUSTOM_SAFE_SERVICE_NETWORKS: number[] = [chainIds.RSK, chainIds.HARMONY];

export type NativeSymbols = 'ETH' | 'XDAI' | 'RBTC' | 'CELO' | 'MATIC' | 'AVAX' | 'AETH' | 'ONE' | 'GLMR' | 'FUSE' | 'BNB';
export enum TXStatus {
    FAILED,
    SUCCESS,
}

export const BULLA_COLLECTION_ADDRESS = '0xD52199A8a2f94d0317641bA8a93d46C320403793';

export enum FactoringConfigVersion {
    V1 = 1,
    V2 = 2,
}

export type FactoringConfig = {
    version: FactoringConfigVersion;
    bullaFactoringToken: TokenInfo;
    poolUnderlyingToken: TokenInfo;
    depositPermissionsContract: string;
    factoringPermissionsContract: string;
    poolName: string;
};

export const SEPOLIA_FACTORING_CONFIG: FactoringConfig = {
    version: FactoringConfigVersion.V2,
    bullaFactoringToken: {
        token: new TokenDto('0xDF0fCe31285dcAB9124bF763AB9E5466723BeF35', 6, 'BFT', 'BFT Coin'),
        icon: BullaLogo,
        variant: TokenVariant.UNKNOWN,
        chainId: chainIds.SEPOLIA,
    },
    poolUnderlyingToken: {
        // https://staging.aave.com/faucet/
        token: new TokenDto('0x94a9D9AC8a22534E3FaCa9F4e7F2E2cf85d5E4C8', 6, 'USDC', 'USD Coin'),
        icon: 'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
        variant: TokenVariant.STABLE,
        chainId: chainIds.SEPOLIA,
    },
    depositPermissionsContract: '0xB39bF6Fcd9bd97F7616FAD7b6118Fc2E911eA1d8',
    factoringPermissionsContract: '0x996e2beFD170CeB741b0072AE97E524Bdf410E9e',
    poolName: 'Bulla TCS Settlement Pool Sepolia v2.0',
};

export const MATIC_FACTORING_CONFIG_V1: FactoringConfig = {
    version: FactoringConfigVersion.V1,
    bullaFactoringToken: {
        token: new TokenDto('0x573119CF113281270F21cFE6537482E84D0300eF', 6, 'BFT', 'BFT Coin'),
        icon: BullaLogo,
        variant: TokenVariant.UNKNOWN,
        chainId: chainIds.MATIC,
    },
    // existing USDC we use on MATIC
    poolUnderlyingToken: {
        token: new TokenDto('0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359', 6, 'USDC', 'USD Coin'),
        icon: 'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
        variant: TokenVariant.STABLE,
        chainId: chainIds.MATIC,
    },
    depositPermissionsContract: '0xBB56c6E4e0812de05bf870941676F6467D964d5e',
    factoringPermissionsContract: '0x72c1cD1C6A7132e58b334E269Ec5bE1adC1030d4',
    poolName: 'Bulla TCS Settlement Pool Matic v1.0',
};

export const MATIC_FACTORING_CONFIG_V2: FactoringConfig = {
    version: FactoringConfigVersion.V2,
    bullaFactoringToken: {
        token: new TokenDto('0xA7033191Eb07DC6205015075B204Ba0544bc460d', 6, 'BFT', 'BFT Coin'),
        icon: BullaLogo,
        variant: TokenVariant.UNKNOWN,
        chainId: chainIds.MATIC,
    },
    // existing USDC we use on MATIC
    poolUnderlyingToken: {
        token: new TokenDto('0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359', 6, 'USDC', 'USD Coin'),
        icon: 'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
        variant: TokenVariant.STABLE,
        chainId: chainIds.MATIC,
    },
    depositPermissionsContract: '0xBB56c6E4e0812de05bf870941676F6467D964d5e',
    factoringPermissionsContract: '0x72c1cD1C6A7132e58b334E269Ec5bE1adC1030d4',
    poolName: 'Bulla TCS Settlement Pool Matic v2.0',
};

export type NetworkConfig = {
    name: string;
    chainId: ChainId;
    logoFileName: string;
    deployedOnBlock?: number;
    label: string;
    connections: {
        rpcUrl: string;
        wssUrl?: string;
        graphEndpoint?: string;
        pollNetwork?: boolean;
    };
    metaMaskRPC: string;
    supportedTokens: Record<string, TokenInfo>;
    blockExplorer: string;
    gasLimitPerTransaction: number;
    bullaManager: string;
    bullaBankerLatest: string;
    bullaClaimAddress: string;
    bullaInstantPaymentAddress: string;
    batchCreate: { address: string; maxClaims: number };
    bullaFinanceAddress?: string;
    frendlendAddress?: string;
    nativeCurrency: {
        name: string;
        symbol: NativeSymbols;
        decimals: number;
        tokenInfo: TokenInfo;
        wrappedTokenSymbol: string;
    };
    infoURL: string;
    updateEveryNBlock: number;
    expectedBlocktime: number;
    isTestnet?: boolean;
    humaConfig?: { eligibilityApiUrl: string; poolAddress: string; humagraphEndpoint: string; poolToken: string };
    factoringConfig?: FactoringConfig[];
    bullaSwap?: string;
};

export const instantPaymentGasCost = 45000; // added about 10% for good measure

export const _allNetworks: NetworkConfig[] = [
    {
        name: 'ETH Mainnet',
        label: 'Ethereum',
        chainId: chainIds.MAINNET,
        logoFileName: EthereumLogo,
        supportedTokens: {
            USDC: {
                token: new TokenDto('0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48', 6, 'USDC', 'USDC'),
                icon: 'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
                variant: TokenVariant.STABLE,
                chainId: chainIds.MAINNET,
            },
            DAI: {
                token: new TokenDto('0x6b175474e89094c44da98b954eedeac495271d0f', 18, 'DAI', 'DAI'),
                icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png',
                variant: TokenVariant.DAI,
                chainId: chainIds.MAINNET,
            },
            WETH: {
                token: new TokenDto('0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2', 18, 'WETH', 'Wrapped Ether'),
                icon: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
                variant: TokenVariant.ETH,
                chainId: chainIds.MAINNET,
            },
            WBTC: {
                token: new TokenDto('0x2260fac5e5542a773aa44fbcfedf7c193bc2c599', 8, 'WBTC', 'Wrapped BTC'),
                icon: 'https://assets.coingecko.com/coins/images/7598/small/wrapped_bitcoin_wbtc.png?1548822744',
                variant: TokenVariant.BTC,
                chainId: chainIds.MAINNET,
            },
            USDT: {
                token: new TokenDto('0xdac17f958d2ee523a2206206994597c13d831ec7', 6, 'USDT', 'Tether USD'),
                icon: 'https://s2.coinmarketcap.com/static/img/coins/200x200/825.png',
                variant: TokenVariant.USDT,
                chainId: chainIds.MAINNET,
            },
            PYUSD: {
                token: new TokenDto('0x6c3ea9036406852006290770BEdFcAbA0e23A0e8', 6, 'PYUSD', 'PayPal USD'),
                icon: 'https://etherscan.io/token/images/paypalusd_32.png',
                variant: TokenVariant.STABLE,
                chainId: chainIds.MAINNET,
            },
        },
        nativeCurrency: {
            name: 'Ether',
            symbol: 'ETH',
            decimals: 18,
            tokenInfo: {
                token: new TokenDto(constants.AddressZero, 18, 'ETH', 'Ether'),
                variant: TokenVariant.ETH,
                icon: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
                chainId: chainIds.MAINNET,
            },
            wrappedTokenSymbol: 'WETH',
        },
        deployedOnBlock: 14837685,
        gasLimitPerTransaction: 5_000_000, // limit is 30m, but it's mainnet
        infoURL: 'https://ethereum.org/',
        metaMaskRPC: `https://eth.meowrpc.com`,
        connections: {
            rpcUrl: `https://go.getblock.io/6798ee4b3de14cce85fba266a875a4f6`,
            graphEndpoint: 'https://api.studio.thegraph.com/query/78913/bulla-contracts-mainnet/version/latest',
        },
        blockExplorer: 'https://etherscan.io/',
        bullaBankerLatest: '0x873C25e47f3C5e4bC524771DFed53B5B36ad5eA2',
        bullaManager: '0x1b4DB52FD952F70d3D28bfbd406dB71940eD8cA9',
        bullaClaimAddress: '0x127948A4286A67A0A5Cb56a2D0d54881077A4889',
        bullaInstantPaymentAddress: '0xec6013D62Af8dfB65B8248204Dd1913d2f1F0181',
        bullaFinanceAddress: '0x5369F71e1Fe238f0e6D938c734E2D2aE7296F362',
        frendlendAddress: '0xbAB429068fc4A5455849f58C4Bf04F398e8006c1',
        batchCreate: { address: '0x6811De39DC03245A15D54e2bc615821f9997bbC4', maxClaims: 100 },
        updateEveryNBlock: 1,
        expectedBlocktime: 20,
    },

    // {
    //     name: 'RSK Mainnet',
    //     label: 'RSK',
    //     chainId: chainIds.RSK,
    //     logoFileName: RskLogo,
    //     supportedTokens: {
    //         DLLR: {
    //             token: new TokenDto('0xc1411567D2670E24D9c4Daaa7CdA95686E1250Aa', 18, 'DLLR', 'SOVRYN DOLLAR'),
    //             icon: 'https://alpha.sovryn.app/static/media/dllr.0f069bfa.svg',
    //             variant: TokenVariant.STABLE,
    //             chainId: chainIds.RSK,
    //         },
    //         DOC: {
    //             token: new TokenDto('0xe700691dA7b9851F2F35f8b8182c69c53CcaD9Db', 18, 'DOC', 'Dollar On Chain'),
    //             icon: '',
    //             variant: TokenVariant.STABLE,
    //             chainId: chainIds.RSK,
    //         },
    //         XUSD: {
    //             token: new TokenDto('0xb5999795BE0EbB5bAb23144AA5FD6A02D080299F', 18, 'XUSD', 'XUSD'),
    //             icon: '',
    //             variant: TokenVariant.STABLE,
    //             chainId: chainIds.RSK,
    //         },
    //         RDOC: {
    //             token: new TokenDto('0x2d919F19D4892381D58edeBeca66D5642Cef1a1f', 18, 'RDOC', 'RIF Dollar On Chain'),
    //             icon: '',
    //             variant: TokenVariant.UNKNOWN,
    //             chainId: chainIds.RSK,
    //         },
    //         WRBTC: {
    //             token: new TokenDto('0x967F8799aF07dF1534d48A95a5C9FEBE92c53AE0', 18, 'WRBTC', 'Wrapped RSK Bitcoin'),
    //             icon: 'https://assets.coingecko.com/coins/images/5070/small/rsk-logo.jpg?1547040475',
    //             variant: TokenVariant.BTC,
    //             chainId: chainIds.RSK,
    //         },
    //         SOV: {
    //             token: new TokenDto('0xEFc78fc7d48b64958315949279Ba181c2114ABBd', 18, 'SOV', 'Sovryn Token'),
    //             icon: 'https://assets.coingecko.com/coins/images/5070/small/rsk-logo.jpg?1547040475',
    //             variant: TokenVariant.UNKNOWN,
    //             chainId: chainIds.RSK,
    //         },
    //         BRZ: {
    //             token: new TokenDto('0xE355c280131dFaF18Bf1c3648aEe3C396dB6b5Fd', 4, 'BRZ', 'Brazilian Digital Token'),
    //             icon: 'https://www.brztoken.io/img/svgs/IMG_LOGOFOOTER.svg',
    //             variant: TokenVariant.BRZ,
    //             chainId: chainIds.RSK,
    //         },
    //     },
    //     nativeCurrency: {
    //         name: 'RSK Mainnet Ether',
    //         symbol: 'RBTC',
    //         decimals: 18,
    //         tokenInfo: {
    //             token: new TokenDto(constants.AddressZero, 18, 'RBTC', 'RSK Bitcoin'),
    //             variant: TokenVariant.BTC,
    //             icon: 'https://assets.coingecko.com/coins/images/5070/small/rsk-logo.jpg?1547040475',
    //             chainId: chainIds.RSK,
    //         },
    //         wrappedTokenSymbol: 'WRBTC',
    //     },
    //     deployedOnBlock: 3788762,
    //     gasLimitPerTransaction: 4000000, // limit is 6800000
    //     infoURL: 'https://rsk.co',
    //     metaMaskRPC: 'https://public-node.rsk.co',
    //     connections: {
    //         rpcUrl: `https://rsk.getblock.io/mainnet/?api_key=${GET_BLOCK_API_KEY}`,
    //         // wssUrl: `wss://rsk.getblock.io/mainnet/?api_key=${GET_BLOCK_API_KEY}`,
    //     },
    //     blockExplorer: 'https://explorer.rsk.co/',
    //     bullaBankerLatest: '0x2Cfd9A72500c0eE511cA3698318649F9d8352A8B',
    //     bullaManager: '0x53d61694146AB6226d0BEf4080617c28Ce250e14',
    //     bullaClaimAddress: '0xC4B52A6D94E8d468De7b2Bef97C49210395fB5Db',
    //     bullaInstantPaymentAddress: '0x2eF2431b2e965Fa3C686323B7b185Cf8ADE5FEe3',
    //     batchCreate: { address: '0xf08227B90bC1bf6C651A8854D10dA76e9f1db1d8', maxClaims: 20 },
    //     updateEveryNBlock: 1,
    //     expectedBlocktime: 40,
    // },
    {
        name: 'Moonbeam',
        label: 'Moonbeam',
        chainId: chainIds.MOONBEAM,
        logoFileName: MoonbeamLogo,
        supportedTokens: {
            USDC: {
                token: new TokenDto('0x818ec0a7fe18ff94269904fced6ae3dae6d6dc0b', 6, 'USDC', 'USD Coin'),
                icon: 'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
                variant: TokenVariant.STABLE,
                chainId: chainIds.MOONBEAM,
            },
            DAI: {
                token: new TokenDto('0x765277EebeCA2e31912C9946eAe1021199B39C61', 18, 'DAI', 'Dai Stablecoin'),
                icon: 'https://assets.coingecko.com/coins/images/11062/small/xdai.png?1614727492',
                variant: TokenVariant.DAI,
                chainId: chainIds.MOONBEAM,
            },
            WGLMR: {
                token: new TokenDto('0xAcc15dC74880C9944775448304B263D191c6077F', 18, 'WGLMR', 'Wrapped Glimmer'),
                icon: 'https://moonbeam.foundation/wp-content/uploads/2021/05/cropped-Moonbeam-Foundation-Favicon.png',
                variant: TokenVariant.GLMR,
                chainId: chainIds.MOONBEAM,
            },
            WBTC: {
                token: new TokenDto('0x922D641a426DcFFaeF11680e5358F34d97d112E1', 8, 'WBTC', 'Wrapped Bitcoin'),
                icon: 'https://assets.coingecko.com/coins/images/7598/small/wrapped_bitcoin_wbtc.png?1548822744',
                variant: TokenVariant.BTC,
                chainId: chainIds.MOONBEAM,
            },
            ETH: {
                token: new TokenDto('0xfA9343C3897324496A05fC75abeD6bAC29f8A40f', 18, 'ETH', 'Wrapped Ether'),
                icon: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
                variant: TokenVariant.ETH,
                chainId: chainIds.MOONBEAM,
            },
            USDT: {
                token: new TokenDto('0xefaeee334f0fd1712f9a8cc375f427d9cdd40d73', 6, 'USDT', 'Tether USD'),
                icon: 'https://s2.coinmarketcap.com/static/img/coins/200x200/825.png',
                variant: TokenVariant.USDT,
                chainId: chainIds.MOONBEAM,
            },
        },
        nativeCurrency: {
            name: 'Moonbeam Glimmer',
            symbol: 'GLMR',
            decimals: 18,
            tokenInfo: {
                token: new TokenDto(constants.AddressZero, 18, 'GLMR', 'Moonbeam Glimmer'),
                icon: 'https://moonbeam.foundation/wp-content/uploads/2021/05/cropped-Moonbeam-Foundation-Favicon.png',
                variant: TokenVariant.GLMR,
                chainId: chainIds.MOONBEAM,
            },
            wrappedTokenSymbol: 'WGLMR',
        },
        deployedOnBlock: 470589,
        gasLimitPerTransaction: 12994000, // doc says so
        infoURL: 'https://moonbeam.network/',
        metaMaskRPC: 'https://rpc.api.moonbeam.network',
        connections: {
            rpcUrl: `https://rpc.api.moonbeam.network`,
            graphEndpoint: 'https://api.studio.thegraph.com/query/78913/bulla-contracts-moonbeam/version/latest',
        },
        blockExplorer: 'https://moonbeam.moonscan.io/',
        bullaBankerLatest: '0xeB0f09EEF3DCc3f35f605dAefa474e6caab96CD6',
        bullaManager: '0xE14E624b29BcDa2ec409BBBf97037fEDe3803797',
        bullaClaimAddress: '0x1c534661326b41c8b8aab5631ECED6D9755ff192',
        bullaInstantPaymentAddress: '0x127948a4286a67a0a5cb56a2d0d54881077a4889',
        bullaFinanceAddress: '0x15250D092a789e2304472eD581D587B714615d24',
        frendlendAddress: '0xBAD5c53cC6b37d706E6153d397864E5fdbD38170',
        batchCreate: { address: '0x0af8C15D19058892cDEA66C8C74B7D7bB696FaD5', maxClaims: 40 },
        updateEveryNBlock: 1,
        expectedBlocktime: 25,
    },
    {
        name: 'Aurora Mainnet',
        label: 'Aurora',
        chainId: chainIds.AURORA,
        logoFileName: AuroraLogo,
        supportedTokens: {
            USDC: {
                token: new TokenDto('0xb12bfca5a55806aaf64e99521918a4bf0fc40802', 6, 'USDC', 'USD Coin'),
                icon: 'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
                variant: TokenVariant.STABLE,
                chainId: chainIds.AURORA,
            },
            WBTC: {
                token: new TokenDto('0xf4eb217ba2454613b15dbdea6e5f22276410e89e', 8, 'WBTC', 'Wrapped Bitcoin'),
                icon: 'https://assets.coingecko.com/coins/images/7598/small/wrapped_bitcoin_wbtc.png?1548822744',
                variant: TokenVariant.BTC,
                chainId: chainIds.AURORA,
            },
            DAI: {
                token: new TokenDto('0xe3520349f477a5f6eb06107066048508498a291b', 18, 'DAI', 'Dai Stablecoin'),
                icon: 'https://assets.coingecko.com/coins/images/11062/small/xdai.png?1614727492',
                variant: TokenVariant.DAI,
                chainId: chainIds.AURORA,
            },
            WETH: {
                token: new TokenDto('0xC9BdeEd33CD01541e1eeD10f90519d2C06Fe3feB', 18, 'WETH', 'Wrapped Ether'),
                icon: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
                variant: TokenVariant.ETH,
                chainId: chainIds.AURORA,
            },
            FRAX: {
                token: new TokenDto('0xDA2585430fEf327aD8ee44Af8F1f989a2A91A3d2', 18, 'FRAX', 'Frax'),
                icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6952.png',
                variant: TokenVariant.STABLE,
                chainId: chainIds.AURORA,
            },
            USDT: {
                token: new TokenDto('0x4988a896b1227218e4a686fde5eabdcabd91571f', 6, 'USDT', 'Tether USD'),
                icon: 'https://s2.coinmarketcap.com/static/img/coins/200x200/825.png',
                variant: TokenVariant.USDT,
                chainId: chainIds.AURORA,
            },
        },
        nativeCurrency: {
            name: 'Aurora Mainnet Ether',
            symbol: 'AETH',
            decimals: 18,
            tokenInfo: {
                icon: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
                token: new TokenDto(constants.AddressZero, 18, 'AETH', 'Aurora ETH'),
                variant: TokenVariant.ETH,
                chainId: chainIds.AURORA,
            },
            wrappedTokenSymbol: 'WETH',
        },
        deployedOnBlock: 59115995,
        gasLimitPerTransaction: 1620000, // according to discord.
        infoURL: 'https://aurora.dev/',
        metaMaskRPC: 'https://mainnet.aurora.dev',
        connections: {
            rpcUrl: 'https://mainnet.aurora.dev',
            wssUrl: 'wss://mainnet.aurora.dev',
            graphEndpoint: 'https://api.studio.thegraph.com/query/78913/bulla-contracts-aurora/version/latest',
        },
        blockExplorer: 'https://explorer.mainnet.aurora.dev/',
        bullaBankerLatest: '0xeB0f09EEF3DCc3f35f605dAefa474e6caab96CD6',
        bullaManager: '0xE14E624b29BcDa2ec409BBBf97037fEDe3803797',
        bullaClaimAddress: '0x1c534661326b41c8b8aab5631ECED6D9755ff192',
        bullaInstantPaymentAddress: '0x1b4DB52FD952F70d3D28bfbd406dB71940eD8cA9',
        batchCreate: { address: '0x0af8C15D19058892cDEA66C8C74B7D7bB696FaD5', maxClaims: 40 },
        updateEveryNBlock: 7,
        expectedBlocktime: 3,
    },
    {
        name: 'Arbitrum',
        label: 'Arbitrum',
        chainId: chainIds.ARBITRUM,
        logoFileName: ArbitrumLogo,
        supportedTokens: {
            USDC: {
                token: new TokenDto('0xaf88d065e77c8cc2239327c5edb3a432268e5831', 6, 'USDC', 'USDC'),
                icon: 'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
                variant: TokenVariant.STABLE,
                chainId: chainIds.ARBITRUM,
            },
            'USDC.e': {
                token: new TokenDto('0xff970a61a04b1ca14834a43f5de4533ebddb5cc8', 6, 'USDC.e', 'USD Coin (bridged)'),
                icon: 'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
                variant: TokenVariant.STABLE,
                chainId: chainIds.ARBITRUM,
            },
            DAI: {
                token: new TokenDto('0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1', 18, 'DAI', 'DAI'),
                icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png',
                variant: TokenVariant.DAI,
                chainId: chainIds.ARBITRUM,
            },
            WETH: {
                token: new TokenDto('0x82aF49447D8a07e3bd95BD0d56f35241523fBab1', 18, 'WETH', 'Wrapped Ether'),
                icon: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
                variant: TokenVariant.ETH,
                chainId: chainIds.ARBITRUM,
            },
            WBTC: {
                token: new TokenDto('0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f', 8, 'WBTC', 'Wrapped BTC'),
                icon: 'https://assets.coingecko.com/coins/images/7598/small/wrapped_bitcoin_wbtc.png?1548822744',
                variant: TokenVariant.BTC,
                chainId: chainIds.ARBITRUM,
            },
            USDT: {
                token: new TokenDto('0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9', 6, 'USDT', 'Tether USD'),
                icon: 'https://s2.coinmarketcap.com/static/img/coins/200x200/825.png',
                variant: TokenVariant.USDT,
                chainId: chainIds.ARBITRUM,
            },
            ARB: {
                token: new TokenDto('0x912CE59144191C1204E64559FE8253a0e49E6548', 18, 'ARB', 'Arbitrum'),
                icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11841.png',
                variant: TokenVariant.UNKNOWN,
                chainId: chainIds.ARBITRUM,
            },
        },
        nativeCurrency: {
            name: 'Ether',
            symbol: 'ETH',
            decimals: 18,
            tokenInfo: {
                icon: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
                token: new TokenDto(constants.AddressZero, 18, 'ETH', 'Arbitrum ETH'),
                variant: TokenVariant.ETH,
                chainId: chainIds.ARBITRUM,
            },
            wrappedTokenSymbol: 'WETH',
        },
        deployedOnBlock: 6898158,
        gasLimitPerTransaction: 150000000, // real limit is 2-300 000 000
        infoURL: 'https://arbitrum.io/',
        metaMaskRPC: 'https://arb1.arbitrum.io/rpc',
        connections: {
            rpcUrl: 'https://arb1.arbitrum.io/rpc',
            graphEndpoint: 'https://api.studio.thegraph.com/query/78913/bulla-contracts-arbitrum/version/latest',
        },
        blockExplorer: 'https://arbiscan.io/',
        bullaBankerLatest: '0xeB0f09EEF3DCc3f35f605dAefa474e6caab96CD6',
        bullaManager: '0xE14E624b29BcDa2ec409BBBf97037fEDe3803797',
        bullaClaimAddress: '0x1c534661326b41c8b8aab5631ECED6D9755ff192',
        bullaInstantPaymentAddress: '0x1b4DB52FD952F70d3D28bfbd406dB71940eD8cA9',
        batchCreate: { address: '0x0af8C15D19058892cDEA66C8C74B7D7bB696FaD5', maxClaims: 255 },
        updateEveryNBlock: 4,
        expectedBlocktime: 3,
        bullaFinanceAddress: '0x5cBfeeF2Af2313Bd9C28484Af7508133Fa99E464',
        frendlendAddress: '0xF75313de7055097b07e621DDD7FEEe9ea3e2147d',
    },
    {
        name: 'Gnosis Chain',
        label: 'Gnosis',
        chainId: chainIds.GNOSIS,
        logoFileName: GnosisChainLogo,
        supportedTokens: {
            USDC: {
                token: new TokenDto('0xDDAfbb505ad214D7b80b1f830fcCc89B60fb7A83', 6, 'USDC', 'USD Coin'),
                icon: 'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
                variant: TokenVariant.STABLE,
                chainId: chainIds.GNOSIS,
            },
            WBTC: {
                token: new TokenDto('0x8e5bBbb09Ed1ebdE8674Cda39A0c169401db4252', 8, 'WBTC', 'Wrapped Bitcoin'),
                icon: 'https://assets.coingecko.com/coins/images/7598/small/wrapped_bitcoin_wbtc.png?1548822744',
                variant: TokenVariant.BTC,
                chainId: chainIds.GNOSIS,
            },
            WETH: {
                token: new TokenDto('0x6A023CCd1ff6F2045C3309768eAd9E68F978f6e1', 18, 'WETH', 'Wrapped Ether'),
                icon: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
                variant: TokenVariant.ETH,
                chainId: chainIds.GNOSIS,
            },
            WXDAI: {
                token: new TokenDto('0xe91D153E0b41518A2Ce8Dd3D7944Fa863463a97d', 18, 'WXDAI', 'Wrapped xDai'),
                icon: 'https://assets.coingecko.com/coins/images/11062/small/xdai.png?1614727492',
                variant: TokenVariant.DAI,
                chainId: chainIds.GNOSIS,
            },
            USDT: {
                token: new TokenDto('0x4ECaBa5870353805a9F068101A40E0f32ed605C6', 6, 'USDT', 'Tether USD'),
                icon: 'https://s2.coinmarketcap.com/static/img/coins/200x200/825.png',
                variant: TokenVariant.USDT,
                chainId: chainIds.GNOSIS,
            },
        },
        deployedOnBlock: 18704278,
        gasLimitPerTransaction: 15000000, // real limit is 30 000 000
        metaMaskRPC: `https://rpc.gnosischain.com`,
        connections: {
            rpcUrl: `https://rpc.gnosischain.com`,
            graphEndpoint: 'https://api.studio.thegraph.com/query/78913/bulla-contracts-gnosis/version/latest',
        },
        blockExplorer: 'https://gnosisscan.io/',
        nativeCurrency: {
            name: 'xDAI',
            symbol: 'XDAI',
            decimals: 18,
            tokenInfo: {
                icon: 'https://assets.coingecko.com/coins/images/11062/small/xdai.png?1614727492',
                token: new TokenDto(constants.AddressZero, 18, 'XDAI', 'Gnosis Chain xDAI'),
                variant: TokenVariant.DAI,
                chainId: chainIds.GNOSIS,
            },
            wrappedTokenSymbol: 'WXDAI',
        },
        infoURL: 'https://forum.poa.network/c/xdai-chain',
        batchCreate: {
            address: '0xe14e624b29bcda2ec409bbbf97037fede3803797',
            maxClaims: 40,
        },
        bullaBankerLatest: '0x53A6762c7037e80ae2E9E5bC90287D5EA2FDAa09',
        bullaManager: '0x7c70dC167ebC555d2F68c93699343636e2A92308',
        bullaClaimAddress: '0xA19d5908e8F56fbf5013282837C4A73c3C5374DB',
        bullaInstantPaymentAddress: '0xA2d3332AdC23109129651A85388eB6561C69074A',
        bullaFinanceAddress: '0x69E7e315a6fea4775e568337ca58F1395C0c01fc',
        frendlendAddress: '0x15C43c1483816C0DEfcb3154b09A9e450d139033',
        updateEveryNBlock: 3,
        expectedBlocktime: 7,
    },
    {
        name: 'Matic(Polygon) Mainnet',
        label: 'Polygon',
        chainId: chainIds.MATIC,
        logoFileName: PolygonLogo,
        supportedTokens: {
            USDC: {
                token: new TokenDto('0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359', 6, 'USDC', 'USD Coin'),
                icon: 'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
                variant: TokenVariant.STABLE,
                chainId: chainIds.MATIC,
            },
            'USDC.e': {
                token: new TokenDto('0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174', 6, 'USDC.e', 'USD Coin'),
                icon: 'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
                variant: TokenVariant.STABLE,
                chainId: chainIds.MATIC,
            },
            DAI: {
                token: new TokenDto('0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063', 18, 'DAI', 'Dai Stablecoin'),
                icon: 'https://assets.coingecko.com/coins/images/11062/small/xdai.png?1614727492',
                variant: TokenVariant.DAI,
                chainId: chainIds.MATIC,
            },
            WMATIC: {
                token: new TokenDto('0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270', 18, 'WMATIC', 'Wrapped Matic'),
                icon: 'https://assets.coingecko.com/coins/images/4713/large/matic-token-icon.png?1624446912',
                variant: TokenVariant.MATIC,
                chainId: chainIds.MATIC,
            },
            WBTC: {
                token: new TokenDto('0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6', 8, 'WBTC', 'Wrapped Bitcoin'),
                icon: 'https://assets.coingecko.com/coins/images/7598/small/wrapped_bitcoin_wbtc.png?1548822744',
                variant: TokenVariant.BTC,
                chainId: chainIds.MATIC,
            },
            WETH: {
                token: new TokenDto('0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619', 18, 'WETH', 'Wrapped Ether'),
                icon: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
                variant: TokenVariant.ETH,
                chainId: chainIds.MATIC,
            },
            USDT: {
                token: new TokenDto('0xc2132d05d31c914a87c6611c10748aeb04b58e8f', 6, 'USDT', 'Tether USD'),
                icon: 'https://s2.coinmarketcap.com/static/img/coins/200x200/825.png',
                variant: TokenVariant.USDT,
                chainId: chainIds.MATIC,
            },
            TCS: {
                token: new TokenDto('0x2f697bc31895ea05e6a364cedc8a76ff3467d32f', 18, 'TCS', 'TCS'),
                icon: 'https://www.tcsblockchain.com/logo.svg',
                variant: TokenVariant.UNKNOWN,
                chainId: chainIds.MATIC,
            },
        },
        deployedOnBlock: 20494269,
        gasLimitPerTransaction: 10000000, // real limit is 20 000 000
        metaMaskRPC: `https://matic-mainnet.chainstacklabs.com/`,
        connections: {
            rpcUrl: `https://go.getblock.io/ffd29467d6a14bd4aadda5718fe898bc`,
            graphEndpoint:
                'https://api.goldsky.com/api/public/project_clxvwihx9eci401ud1suddgpf/subgraphs/bulla-contracts-polygon/latest/gn',
        },
        blockExplorer: 'https://polygonscan.com/',
        nativeCurrency: {
            name: 'MATIC',
            symbol: 'MATIC',
            decimals: 18,
            tokenInfo: {
                token: new TokenDto(constants.AddressZero, 18, 'MATIC', 'Matic'),
                icon: 'https://assets.coingecko.com/coins/images/4713/large/matic-token-icon.png?1624446912',
                variant: TokenVariant.MATIC,
                chainId: chainIds.MATIC,
            },
            wrappedTokenSymbol: 'WMATIC',
        },
        infoURL: 'https://polygon.technology/',
        bullaBankerLatest: '0x85Acc8E44d732eFF1ddec75a1ee93D6e4A033eF8',
        bullaManager: '0x48D283521Ff91a1e83A16a7138B549C34BeDD44c',
        bullaClaimAddress: '0x5A809C17d33c92f9EFF31e579E9DeDF247e1EBe4',
        bullaInstantPaymentAddress: '0x712359c61534c5da10821c09d0e9c7c2312e1d91',
        batchCreate: { address: '0xfbdf5dff946200B038e1455E532D9795F030aB91', maxClaims: 40 },
        bullaFinanceAddress: '0xF7d793dc425E64CA93Cf92bb6cfF6173bdbA3995',
        frendlendAddress: '0x60b49BB18b60dE3A95235A4d5F42062dD1D461E2',
        updateEveryNBlock: 10,
        expectedBlocktime: 15,
        factoringConfig: [MATIC_FACTORING_CONFIG_V2, MATIC_FACTORING_CONFIG_V1],
    },
    {
        name: 'Optimism',
        label: 'Optimism',
        chainId: chainIds.OPTIMISM,
        logoFileName: OptimismLogo,
        supportedTokens: {
            USDC: {
                token: new TokenDto('0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85', 6, 'USDC', 'USD Coin'),
                icon: 'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
                variant: TokenVariant.STABLE,
                chainId: chainIds.OPTIMISM,
            },
            'USDC.e': {
                token: new TokenDto('0x7F5c764cBc14f9669B88837ca1490cCa17c31607', 6, 'USDC.e', 'USD Coin'),
                icon: 'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
                variant: TokenVariant.STABLE,
                chainId: chainIds.OPTIMISM,
            },
            DAI: {
                token: new TokenDto('0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1', 18, 'DAI', 'Dai Stablecoin'),
                icon: 'https://assets.coingecko.com/coins/images/11062/small/xdai.png?1614727492',
                variant: TokenVariant.DAI,
                chainId: chainIds.OPTIMISM,
            },
            WETH: {
                token: new TokenDto('0x4200000000000000000000000000000000000006', 18, 'WETH', 'Wrapped Ether'),
                icon: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
                variant: TokenVariant.ETH,
                chainId: chainIds.OPTIMISM,
            },
            WBTC: {
                token: new TokenDto('0x68f180fcCe6836688e9084f035309E29Bf0A2095', 8, 'WBTC', 'Wrapped Bitcoin'),
                icon: 'https://assets.coingecko.com/coins/images/7598/small/wrapped_bitcoin_wbtc.png?1548822744',
                variant: TokenVariant.BTC,
                chainId: chainIds.OPTIMISM,
            },
            USDT: {
                token: new TokenDto('0x94b008aA00579c1307B0EF2c499aD98a8ce58e58', 6, 'USDT', 'Tether USD'),
                icon: 'https://s2.coinmarketcap.com/static/img/coins/200x200/825.png',
                variant: TokenVariant.USDT,
                chainId: chainIds.OPTIMISM,
            },
        },
        deployedOnBlock: 74562791,
        gasLimitPerTransaction: 15000000, // real limit is 30 000 000
        metaMaskRPC: `https://mainnet.optimism.io`,
        connections: {
            wssUrl: `wss://opt-mainnet.g.alchemy.com/v2/AgPafMyolA8rbO9wspKBfpYqusTimQkP`,
            rpcUrl: `https://opt-mainnet.g.alchemy.com/v2/AgPafMyolA8rbO9wspKBfpYqusTimQkP`,
            graphEndpoint: 'https://api.studio.thegraph.com/query/78913/bulla-contracts-optimism/version/latest',
        },
        blockExplorer: 'https://optimistic.etherscan.io/',
        nativeCurrency: {
            name: 'Optimism Ether',
            symbol: 'ETH',
            decimals: 18,
            tokenInfo: {
                token: new TokenDto(constants.AddressZero, 18, 'ETH', 'Optimism Ether'),
                icon: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
                variant: TokenVariant.ETH,
                chainId: chainIds.OPTIMISM,
            },
            wrappedTokenSymbol: 'WETH',
        },
        infoURL: 'https://www.optimism.io/',
        bullaBankerLatest: '0xce704a7Fae206ad009852258dDD8574B844eDa3b',
        bullaManager: '0xE14E624b29BcDa2ec409BBBf97037fEDe3803797',
        bullaClaimAddress: '0x0af8C15D19058892cDEA66C8C74B7D7bB696FaD5',
        bullaInstantPaymentAddress: '0xbe25A1086DE2b587B2D20E4B14c442cdA2437945',
        bullaFinanceAddress: '0x5369F71e1Fe238f0e6D938c734E2D2aE7296F362',
        frendlendAddress: '0x3702D060cbB102b6AebF40B40880F77BeF3d7225',
        batchCreate: { address: '0x6985d6af038f177438a6681d1F64d4409Dc8AaC2', maxClaims: 55 },
        updateEveryNBlock: 8,
        expectedBlocktime: 10,
    },
    {
        name: 'Harmony Mainnet Shard 0',
        label: 'Harmony',
        chainId: chainIds.HARMONY,
        logoFileName: HarmonyLogo,
        supportedTokens: {
            '1USDC': {
                token: new TokenDto('0x985458e523db3d53125813ed68c274899e9dfab4', 6, '1USDC', 'USD Coin'),
                variant: TokenVariant.STABLE,
                icon: 'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
                chainId: chainIds.HARMONY,
            },
            WONE: {
                token: new TokenDto('0xcf664087a5bb0237a0bad6742852ec6c8d69a27a', 18, 'wONE', 'Wrapped Harmony ONE'),
                variant: TokenVariant.ONE,
                icon: 'https://assets.coingecko.com/coins/images/4344/small/Y88JAze.png?1565065793',
                chainId: chainIds.HARMONY,
            },
            '1ETH': {
                token: new TokenDto('0x6983d1e6def3690c4d616b13597a09e6193ea013', 18, '1ETH', 'ETH on Harmony'),
                variant: TokenVariant.ETH,
                icon: 'https://assets.coingecko.com/coins/images/15090/small/logo_200_eth.png?1619665764',
                chainId: chainIds.HARMONY,
            },
            '1BTC': {
                token: new TokenDto('0x3095c7557bcb296ccc6e363de01b760ba031f2d9', 8, '1BTC', 'BTC on Harmony'),
                variant: TokenVariant.BTC,
                icon: 'https://assets.coingecko.com/coins/images/7598/small/wrapped_bitcoin_wbtc.png?1548822744',
                chainId: chainIds.HARMONY,
            },
            USDT: {
                token: new TokenDto('0x3c2b8be99c50593081eaa2a724f0b8285f5aba8f', 6, 'USDT', 'Tether USD'),
                icon: 'https://s2.coinmarketcap.com/static/img/coins/200x200/825.png',
                variant: TokenVariant.USDT,
                chainId: chainIds.HARMONY,
            },
        },
        deployedOnBlock: 18836051,
        gasLimitPerTransaction: 40000000, // real limit 80 000 000
        metaMaskRPC: `https://api.harmony.one`,
        connections: {
            rpcUrl: `https://api.harmony.one`,
            wssUrl: 'wss://ws.s0.t.hmny.io',
            graphEndpoint: 'https://api.studio.thegraph.com/query/78913/bulla-contracts-harmony/version/latest',
            pollNetwork: true,
        },
        batchCreate: {
            address: '0xe14e624b29bcda2ec409bbbf97037fede3803797',
            maxClaims: 40,
        },
        blockExplorer: 'https://explorer.harmony.one/',
        nativeCurrency: {
            name: 'ONE',
            symbol: 'ONE',
            decimals: 18,
            tokenInfo: {
                token: new TokenDto(constants.AddressZero, 18, 'ONE', 'Harmony ONE'),
                icon: 'https://assets.coingecko.com/coins/images/4344/small/Y88JAze.png?1565065793',
                variant: TokenVariant.ONE,
                chainId: chainIds.HARMONY,
            },
            wrappedTokenSymbol: 'WONE',
        },
        infoURL: 'https://harmony.one',
        bullaBankerLatest: '0x6811De39DC03245A15D54e2bc615821f9997bbC4',
        bullaManager: '0x127948A4286A67A0A5Cb56a2D0d54881077A4889',
        bullaClaimAddress: '0x873C25e47f3C5e4bC524771DFed53B5B36ad5eA2',
        bullaInstantPaymentAddress: '0x4f71b513164127e68a2877e42811c45F632cda5F',
        bullaFinanceAddress: '0x15250D092a789e2304472eD581D587B714615d24',
        frendlendAddress: '0xBAD5c53cC6b37d706E6153d397864E5fdbD38170',
        updateEveryNBlock: 6,
        expectedBlocktime: 3,
    },
    {
        name: 'Avalanche Mainnet',
        label: 'Avalanche',
        chainId: chainIds.AVALANCHE,
        logoFileName: AvalancheLogo,
        supportedTokens: {
            USDC: {
                token: new TokenDto('0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E', 6, 'USDC', 'USD Coin'),
                icon: 'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
                variant: TokenVariant.STABLE,
                chainId: chainIds.AVALANCHE,
            },
            'USDC.e': {
                token: new TokenDto('0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664', 6, 'USDC.e', 'USD Coin (bridged)'),
                icon: 'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
                variant: TokenVariant.STABLE,
                chainId: chainIds.AVALANCHE,
            },
            DAI: {
                token: new TokenDto('0xd586E7F844cEa2F87f50152665BCbc2C279D8d70', 18, 'DAI', 'Dai Stablecoin'),
                icon: 'https://assets.coingecko.com/coins/images/11062/small/xdai.png?1614727492',
                variant: TokenVariant.DAI,
                chainId: chainIds.AVALANCHE,
            },
            WAVAX: {
                token: new TokenDto('0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7', 18, 'WAVAX', 'Wrapped Avalanche'),
                icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png',
                variant: TokenVariant.AVAX,
                chainId: chainIds.AVALANCHE,
            },
            WETH: {
                token: new TokenDto('0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB', 18, 'WETH', 'Wrapped Ether'),
                icon: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
                variant: TokenVariant.ETH,
                chainId: chainIds.AVALANCHE,
            },
            WBTC: {
                token: new TokenDto('0x50b7545627a5162F82A992c33b87aDc75187B218', 8, 'WBTC', 'Wrapped Bitcoin'),
                icon: 'https://assets.coingecko.com/coins/images/7598/small/wrapped_bitcoin_wbtc.png?1548822744',
                variant: TokenVariant.BTC,
                chainId: chainIds.AVALANCHE,
            },
            THOR: {
                token: new TokenDto('0x8F47416CaE600bccF9530E9F3aeaA06bdD1Caa79', 18, 'THOR', 'THOR v2'),
                icon: 'https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0x8F47416CaE600bccF9530E9F3aeaA06bdD1Caa79/logo.png',
                variant: TokenVariant.UNKNOWN,
                chainId: chainIds.AVALANCHE,
            },
            USDT: {
                token: new TokenDto('0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7', 6, 'USDT', 'Tether USD'),
                icon: 'https://s2.coinmarketcap.com/static/img/coins/200x200/825.png',
                variant: TokenVariant.USDT,
                chainId: chainIds.AVALANCHE,
            },
            'USDT.e': {
                token: new TokenDto('0xc7198437980c041c805a1edcba50c1ce5db95118', 6, 'USDT.e', 'Tether USD (bridged)'),
                icon: 'https://s2.coinmarketcap.com/static/img/coins/200x200/825.png',
                variant: TokenVariant.USDT,
                chainId: chainIds.AVALANCHE,
            },
        },
        deployedOnBlock: 5968486,
        gasLimitPerTransaction: 4000000, // real limit 8 000 000
        metaMaskRPC: `https://avax.meowrpc.com`,
        connections: {
            rpcUrl: `https://avalanche-mainnet.core.chainstack.com/ext/bc/C/rpc/c3e5daa97eb95ebd06b9b1b553ca0ebe`,
            graphEndpoint: 'https://api.studio.thegraph.com/query/78913/bulla-contracts-avalanche/version/latest',
        },
        blockExplorer: 'https://cchain.explorer.avax.network/',
        nativeCurrency: {
            name: 'AVAX',
            symbol: 'AVAX',
            decimals: 18,
            tokenInfo: {
                token: new TokenDto(constants.AddressZero, 18, 'AVAX', 'Avalanche'),
                icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png',
                variant: TokenVariant.AVAX,
                chainId: chainIds.AVALANCHE,
            },
            wrappedTokenSymbol: 'WAVAX',
        },
        infoURL: 'https://www.avax.network/',
        bullaBankerLatest: '0x4Ba1453df995F4AAe2ef610A9c06fEecF0Fe2581',
        batchCreate: {
            address: '0x1c534661326b41c8b8aab5631ECED6D9755ff192',
            maxClaims: 40,
        },
        bullaManager: '0xAA6E5B4E34f3C3BA4D90694909dca7DDdf058079',
        bullaClaimAddress: '0x68da2c92b60337aA55BAcF0d7e61126eDb535752',
        bullaInstantPaymentAddress: '0xFcA0E74af938919E43541D40330212324C8aF27F',
        bullaFinanceAddress: '0x15250D092a789e2304472eD581D587B714615d24',
        frendlendAddress: '0xBAD5c53cC6b37d706E6153d397864E5fdbD38170',
        updateEveryNBlock: 6,
        expectedBlocktime: 3,
    },
    {
        name: 'Celo Mainnet',
        label: 'Celo',
        chainId: chainIds.CELO,
        logoFileName: CeloLogo,
        supportedTokens: {
            cUSD: {
                token: new TokenDto('0x765DE816845861e75A25fCA122bb6898B8B1282a', 18, 'cUSD', 'Celo Dollar'),
                icon: 'https://s2.coinmarketcap.com/static/img/coins/200x200/7236.png',
                variant: TokenVariant.STABLE,
                chainId: chainIds.CELO,
            },
            cETH: {
                token: new TokenDto('0x2DEf4285787d58a2f811AF24755A8150622f4361', 18, 'cETH', 'Wrapped Ether'),
                icon: 'https://res.cloudinary.com/sushi-cdn/image/fetch/w_64/https://raw.githubusercontent.com/sushiswap/logos/main/network/celo/0x2DEf4285787d58a2f811AF24755A8150622f4361.jpg',
                variant: TokenVariant.ETH,
                chainId: chainIds.CELO,
            },
            cEUR: {
                token: new TokenDto('0xD8763CBa276a3738E6DE85b4b3bF5FDed6D6cA73', 18, 'cEUR', 'Celo Euro'),
                icon: 'https://assets.bidali.com/currencies/ceur.png',
                variant: TokenVariant.EUR,
                chainId: chainIds.CELO,
            },
            USDT: {
                token: new TokenDto('0x88eeC49252c8cbc039DCdB394c0c2BA2f1637EA0', 6, 'USDT', 'Tether USD'),
                icon: 'https://s2.coinmarketcap.com/static/img/coins/200x200/825.png',
                variant: TokenVariant.USDT,
                chainId: chainIds.CELO,
            },
        },
        deployedOnBlock: 9469593,
        gasLimitPerTransaction: 5000000, // real limit 10 000 000
        metaMaskRPC: 'https://forno.celo.org',
        connections: {
            rpcUrl: 'https://forno.celo.org',
            wssUrl: 'wss://forno.celo.org/ws',
            graphEndpoint: 'https://api.studio.thegraph.com/query/78913/bulla-contracts-celo/version/latest',
        },
        blockExplorer: 'https://explorer.celo.org/',
        nativeCurrency: {
            name: 'CELO',
            symbol: 'CELO',
            decimals: 18,
            tokenInfo: {
                token: new TokenDto('0x471EcE3750Da237f93B8E339c536989b8978a438', 18, 'CELO', 'Celo'),
                icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7236.png',
                variant: TokenVariant.CELO,
                chainId: chainIds.CELO,
            },
            wrappedTokenSymbol: 'CELO',
        },
        infoURL: 'https://docs.celo.org/',
        bullaBankerLatest: '0xd2A779d4B8d2E4E7faFC0DA6F9d712fE19464D5B',
        batchCreate: {
            address: '0xe14e624b29bcda2ec409bbbf97037fede3803797',
            maxClaims: 40,
        },
        bullaManager: '0x68da2c92b60337aA55BAcF0d7e61126eDb535752',
        bullaClaimAddress: '0x4Ba1453df995F4AAe2ef610A9c06fEecF0Fe2581',
        bullaInstantPaymentAddress: '0x48D283521Ff91a1e83A16a7138B549C34BeDD44c',
        bullaFinanceAddress: '0x15250D092a789e2304472eD581D587B714615d24',
        frendlendAddress: '0xBAD5c53cC6b37d706E6153d397864E5fdbD38170',
        updateEveryNBlock: 3,
        expectedBlocktime: 10,
    },
    {
        name: 'Fuse Mainnet',
        label: 'Fuse',
        chainId: chainIds.FUSE,
        logoFileName: FuseLogo,
        supportedTokens: {
            USDC: {
                token: new TokenDto('0x620fd5fa44BE6af63715Ef4E65DDFA0387aD13F5', 6, 'USDC', 'USD Coin'),
                icon: 'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
                variant: TokenVariant.STABLE,
                chainId: chainIds.FUSE,
            },
            WFUSE: {
                token: new TokenDto('0x0BE9e53fd7EDaC9F859882AfdDa116645287C629', 18, 'WFUSE', 'Wrapped Fuse'),
                icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5634.png',
                variant: TokenVariant.FUSE,
                chainId: chainIds.FUSE,
            },
            WETH: {
                token: new TokenDto('0xa722c13135930332Eb3d749B2F0906559D2C5b99', 18, 'WETH', 'Wrapped Ether'),
                icon: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
                variant: TokenVariant.ETH,
                chainId: chainIds.FUSE,
            },
            WBTC: {
                token: new TokenDto('0x33284f95ccb7B948d9D352e1439561CF83d8d00d', 8, 'WBTC', 'Wrapped Bitcoin'),
                icon: 'https://assets.coingecko.com/coins/images/7598/small/wrapped_bitcoin_wbtc.png?1548822744',
                variant: TokenVariant.BTC,
                chainId: chainIds.FUSE,
            },
            DAI: {
                token: new TokenDto('0x94Ba7A27c7A95863d1bdC7645AC2951E0cca06bA', 18, 'DAI', 'Dai Stablecoin'),
                icon: 'https://assets.coingecko.com/coins/images/11062/small/xdai.png?1614727492',
                variant: TokenVariant.DAI,
                chainId: chainIds.FUSE,
            },
            USDT: {
                token: new TokenDto('0xFaDbBF8Ce7D5b7041bE672561bbA99f79c532e10', 6, 'USDT', 'Tether USD'),
                icon: 'https://s2.coinmarketcap.com/static/img/coins/200x200/825.png',
                variant: TokenVariant.USDT,
                chainId: chainIds.FUSE,
            },
            G$: {
                token: new TokenDto('0x495d133B938596C9984d462F007B676bDc57eCEC', 2, 'G$', 'GoodDollar'),
                icon: 'https://s2.coinmarketcap.com/static/img/coins/200x200/6961.png',
                variant: TokenVariant.UNKNOWN,
                chainId: chainIds.FUSE,
            },
        },
        deployedOnBlock: 18158523,
        gasLimitPerTransaction: 5000000, // real limit 10 000 000
        metaMaskRPC: 'https://fuse-pokt.nodies.app',
        connections: {
            rpcUrl: 'https://fuse-pokt.nodies.app',
            graphEndpoint: 'https://api.studio.thegraph.com/query/78913/bulla-contracts-fuse/version/latest',
        },
        blockExplorer: 'https://explorer.fuse.io/',
        nativeCurrency: {
            name: 'FUSE',
            symbol: 'FUSE',
            decimals: 18,
            tokenInfo: {
                token: new TokenDto(constants.AddressZero, 18, 'FUSE', 'Fuse'),
                icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5634.png',
                variant: TokenVariant.FUSE,
                chainId: chainIds.FUSE,
            },
            wrappedTokenSymbol: 'WFUSE',
        },
        infoURL: 'https://docs.fuse.io/',
        bullaBankerLatest: '0xce704a7Fae206ad009852258dDD8574B844eDa3b',
        batchCreate: {
            address: '0x6985d6af038f177438a6681d1F64d4409Dc8AaC2',
            maxClaims: 30,
        },
        bullaManager: '0xeB0f09EEF3DCc3f35f605dAefa474e6caab96CD6',
        bullaClaimAddress: '0x0af8C15D19058892cDEA66C8C74B7D7bB696FaD5',
        bullaInstantPaymentAddress: '0xbe25A1086DE2b587B2D20E4B14c442cdA2437945',
        bullaFinanceAddress: '0x15250D092a789e2304472eD581D587B714615d24',
        frendlendAddress: '0xBAD5c53cC6b37d706E6153d397864E5fdbD38170',
        updateEveryNBlock: 4,
        expectedBlocktime: 5,
    },
    {
        name: 'Sepolia Testnet',
        label: 'Sepolia',
        chainId: chainIds.SEPOLIA,
        isTestnet: true,
        logoFileName: SepoliaLogo,
        deployedOnBlock: 5096555,
        gasLimitPerTransaction: 10000000,
        supportedTokens: {
            WETH: {
                token: new TokenDto('0x7b79995e5f793A07Bc00c21412e50Ecae098E7f9', 18, 'WETH', 'Wrapped Ether'),
                icon: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
                variant: TokenVariant.UNKNOWN,
                chainId: chainIds.SEPOLIA,
            },
            USDC: SEPOLIA_FACTORING_CONFIG.poolUnderlyingToken,
            BFT: SEPOLIA_FACTORING_CONFIG.bullaFactoringToken,
            LINK: {
                token: new TokenDto('0x779877A7B0D9E8603169DdbD7836e478b4624789', 18, 'LINK', 'Link'),
                icon: 'https://assets.coingecko.com/coins/images/877/standard/chainlink-new-logo.png?1696502009',
                variant: TokenVariant.UNKNOWN,
                chainId: chainIds.SEPOLIA,
            },
        },
        metaMaskRPC: `https://ethereum-sepolia-rpc.publicnode.com`,
        connections: {
            rpcUrl: `https://go.getblock.io/d110947cc58e46b396d6e7ac01e86cfb`,
            graphEndpoint: 'https://api.studio.thegraph.com/query/78913/bulla-contracts-sepolia/version/latest',
        },
        nativeCurrency: {
            name: 'Sepolia Ether',
            symbol: 'ETH',
            decimals: 18,
            tokenInfo: {
                icon: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
                token: new TokenDto(constants.AddressZero, 18, 'ETH', 'Ether'),
                variant: TokenVariant.UNKNOWN,
                chainId: chainIds.SEPOLIA,
            },
            wrappedTokenSymbol: 'WETH',
        },
        blockExplorer: 'https://sepolia.etherscan.io/',
        infoURL: 'https://sepolia.dev/',
        bullaBankerLatest: '0x5369F71e1Fe238f0e6D938c734E2D2aE7296F362',
        bullaManager: '0x15C43c1483816C0DEfcb3154b09A9e450d139033',
        bullaClaimAddress: '0x3702D060cbB102b6AebF40B40880F77BeF3d7225',
        bullaInstantPaymentAddress: '0x1cD1A83C2965CB7aD55d60551877Eb390e9C3d7A',
        batchCreate: { address: '0xbAB429068fc4A5455849f58C4Bf04F398e8006c1', maxClaims: 20 },
        bullaFinanceAddress: '0xB219ecd037E8A5410d2e8839586D9F3996685cfB',
        frendlendAddress: '0x3E058834CE20A54F0755889c008D3fF62D33cE85',
        updateEveryNBlock: 2,
        expectedBlocktime: 20,
        factoringConfig: [SEPOLIA_FACTORING_CONFIG],
        bullaSwap: '0xdf30BE5964a7E26b49551a430e85B51148A7b95E',
    },
    {
        name: 'Base Mainnet',
        label: 'Base',
        chainId: chainIds.BASE,
        logoFileName: BaseLogo,
        deployedOnBlock: 2715267,
        gasLimitPerTransaction: 15000000,
        supportedTokens: {
            USDbC: {
                token: new TokenDto('0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA', 6, 'USDbC', 'USD Base Coin'),
                icon: 'https://basescan.org/token/images/usdbc_ofc_32.png',
                variant: TokenVariant.USDC,
                chainId: chainIds.BASE,
            },
            USDC: {
                token: new TokenDto('0x833589fcd6edb6e08f4c7c32d4f71b54bda02913', 6, 'USDC', 'USD Coin'),
                icon: 'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
                variant: TokenVariant.USDC,
                chainId: chainIds.BASE,
            },
            OAK: {
                token: new TokenDto('0x845598Da418890a674cbaBA26b70807aF0c61dFE', 6, 'OAK', 'Oak'),
                icon: 'https://swap.oak.community/icons/oak-icon.png',
                variant: TokenVariant.STABLE,
                chainId: chainIds.BASE,
            },
            DAI: {
                token: new TokenDto('0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb', 18, 'DAI', 'DAI'),
                icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png',
                variant: TokenVariant.DAI,
                chainId: chainIds.BASE,
            },
            WETH: {
                token: new TokenDto('0x4200000000000000000000000000000000000006', 18, 'WETH', 'Wrapped Ether'),
                icon: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
                variant: TokenVariant.ETH,
                chainId: chainIds.BASE,
            },
            cbETH: {
                token: new TokenDto('0x2Ae3F1Ec7F1F5012CFEab0185bfc7aa3cf0DEc22', 18, 'cbETH', 'Coinbase Wrapped Staked ETH'),
                icon: 'https://basescan.org/token/images/coinbasecbeth_32.png',
                variant: TokenVariant.ETH,
                chainId: chainIds.BASE,
            },
        },
        metaMaskRPC: `https://mainnet.base.org`,
        connections: {
            rpcUrl: `https://go.getblock.io/baa5ee5a142c482c91a7223b0d1ebf38`,
            graphEndpoint: 'https://api.studio.thegraph.com/query/78913/bulla-contracts-base/version/latest',
        },
        nativeCurrency: {
            name: 'Base Ether',
            symbol: 'ETH',
            decimals: 18,
            tokenInfo: {
                icon: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
                token: new TokenDto(constants.AddressZero, 18, 'ETH', 'Ether'),
                variant: TokenVariant.ETH,
                chainId: chainIds.BASE,
            },
            wrappedTokenSymbol: 'WETH',
        },
        blockExplorer: 'https://basescan.org/',
        infoURL: 'https://base.org/',
        bullaBankerLatest: '0x6811De39DC03245A15D54e2bc615821f9997bbC4',
        bullaManager: '0x127948A4286A67A0A5Cb56a2D0d54881077A4889',
        bullaClaimAddress: '0x873C25e47f3C5e4bC524771DFed53B5B36ad5eA2',
        bullaInstantPaymentAddress: '0x26719d2A1073291559A9F5465Fafe73972B31b1f',
        batchCreate: { address: '0xec6013D62Af8dfB65B8248204Dd1913d2f1F0181', maxClaims: 255 },
        bullaFinanceAddress: '0xd3A33aE646701507eB043e2DB16f8C1428241F53',
        frendlendAddress: '0x44ad74A14f268551Dd8619B094769C10089239C8',
        updateEveryNBlock: 2,
        expectedBlocktime: 4,
    },
    {
        name: 'Binance Chain Mainnet',
        label: 'BNB',
        chainId: chainIds.BNB,
        logoFileName: BNBLogo,
        deployedOnBlock: 28430142,
        gasLimitPerTransaction: 65000000,
        supportedTokens: {
            USDC: {
                token: new TokenDto('0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d', 18, 'USDC', 'USD Coin'),
                icon: 'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
                variant: TokenVariant.USDC,
                chainId: chainIds.BNB,
            },
            WETH: {
                token: new TokenDto('0x2170ed0880ac9a755fd29b2688956bd959f933f8', 18, 'WETH', 'Wrapped Ether'),
                icon: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
                variant: TokenVariant.ETH,
                chainId: chainIds.BNB,
            },
            DAI: {
                token: new TokenDto('0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3', 18, 'DAI', 'DAI'),
                icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png',
                variant: TokenVariant.DAI,
                chainId: chainIds.BNB,
            },
            WBTC: {
                token: new TokenDto('0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c', 18, 'WBTC', 'Wrapped BTC'),
                icon: 'https://assets.coingecko.com/coins/images/7598/small/wrapped_bitcoin_wbtc.png?1548822744',
                variant: TokenVariant.BTC,
                chainId: chainIds.BNB,
            },
            BUSD: {
                token: new TokenDto('0xe9e7cea3dedca5984780bafc599bd69add087d56', 18, 'BUSD', 'BUSD'),
                icon: 'https://assets.coingecko.com/coins/images/9576/small/BUSD.png',
                variant: TokenVariant.UNKNOWN,
                chainId: chainIds.BNB,
            },
            WBNB: {
                token: new TokenDto('0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18, 'WBNB', 'Wrapped BNB'),
                icon: 'https://assets.coingecko.com/coins/images/825/small/bnb-icon2_2x.png',
                variant: TokenVariant.UNKNOWN,
                chainId: chainIds.BNB,
            },
        },
        metaMaskRPC: `https://bsc.publicnode.com`,
        connections: {
            rpcUrl: `https://bsc.publicnode.com`,
            graphEndpoint: 'https://api.studio.thegraph.com/query/78913/bulla-contracts-bnb-chain/version/latest',
        },
        nativeCurrency: {
            name: 'Binance Chain Native Token',
            symbol: 'BNB',
            decimals: 18,
            tokenInfo: {
                icon: 'https://assets.coingecko.com/coins/images/825/small/bnb-icon2_2x.png',
                token: new TokenDto(constants.AddressZero, 18, 'BNB', 'BNB'),
                variant: TokenVariant.UNKNOWN,
                chainId: chainIds.BNB,
            },
            wrappedTokenSymbol: 'WBNB',
        },
        blockExplorer: 'https://bscscan.com/',
        infoURL: 'https://www.binance.org',
        bullaBankerLatest: '0x69E7e315a6fea4775e568337ca58F1395C0c01fc',
        bullaManager: '0x15250D092a789e2304472eD581D587B714615d24',
        bullaClaimAddress: '0xBAD5c53cC6b37d706E6153d397864E5fdbD38170',
        bullaInstantPaymentAddress: '0xa9a04d0C22B6f264BC72a108d124f25BD199c928',
        batchCreate: { address: '0x15C43c1483816C0DEfcb3154b09A9e450d139033', maxClaims: 20 },
        bullaFinanceAddress: '0x3702D060cbB102b6AebF40B40880F77BeF3d7225',
        frendlendAddress: '0x5369F71e1Fe238f0e6D938c734E2D2aE7296F362',
        updateEveryNBlock: 6,
        expectedBlocktime: 4,
    },
];

const localhost: NetworkConfig = {
    name: 'localhost',
    label: 'localhost',
    isTestnet: true,
    chainId: chainIds.LOCALHOST,
    logoFileName: EthereumLogo,
    supportedTokens: {
        WETH: {
            token: new TokenDto('0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9', 18, 'WETH', 'Wrapped Ether'),
            icon: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
            variant: TokenVariant.ETH,
            chainId: chainIds.LOCALHOST,
        },
    },
    deployedOnBlock: 1,
    gasLimitPerTransaction: 10000000,
    metaMaskRPC: 'https://localhost:8545',
    connections: {
        rpcUrl: 'https://localhost:8545',
        wssUrl: 'ws://localhost:8545',
    },
    blockExplorer: 'https://etherscan.io/',
    nativeCurrency: {
        name: 'Ether',
        symbol: 'ETH',
        decimals: 18,
        tokenInfo: {
            icon: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
            token: new TokenDto(constants.AddressZero, 18, 'ETH', 'Ether'),
            variant: TokenVariant.ETH,
            chainId: chainIds.LOCALHOST,
        },
        wrappedTokenSymbol: 'WETH',
    },
    infoURL: '',
    bullaManager: '0x5FbDB2315678afecb367f032d93F642f64180aa3',
    bullaBankerLatest: '0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0',
    bullaClaimAddress: '0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512',
    bullaInstantPaymentAddress: '0xce704a7Fae206ad009852258dDD8574B844eDa3b',
    batchCreate: {
        address: '0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9',
        maxClaims: 20, //could be whatever amount
    },
    updateEveryNBlock: 3,
    expectedBlocktime: 5,
};

export const SWAP_SUPPORTED_NETWORKS = _allNetworks
    .filter(network => network.bullaSwap !== undefined)
    .map(network => network.chainId) as readonly ChainId[];

export const allNetworks = _allNetworks;
export const TESTNET_CHAIN_IDS = allNetworks.filter(x => !!x.isTestnet).map(x => x.chainId);

export const NETWORKS = allNetworks.reduce<{ [chainId: number]: NetworkConfig }>((acc, item) => ({ ...acc, [item.chainId]: item }), {});
export const SUPPORTED_NETWORKS: ChainId[] = allNetworks.map(({ chainId }) => chainId);
export const UNSUPPORTED_PREMIUM_NETWORKS = [Number(chainIds.SEPOLIA), Number(chainIds.RSK)];

type EthWeb3Provider = Web3Provider & {
    provider: {
        isConnected: () => boolean;
        close: () => void;
    };
};

export const establishWebsocket = (wssUrl: string, setProvider: (provider: providers.JsonRpcProvider) => void) => {
    const getProvider = () => new ethers.providers.Web3Provider(ethProvider([wssUrl])) as EthWeb3Provider;
    let provider = getProvider();

    const monitor = setInterval(() => {
        const isConnected = provider.provider.isConnected();
        console.debug('🕵🏼‍♂️ Monitor: websocket is ', isConnected ? 'connected' : 'disconnected');
        if (!isConnected) {
            console.debug('Websocket connection lost, reconnecting...');
            provider.provider.close?.();

            const newProvider = getProvider();
            provider = newProvider;
            setProvider(newProvider);
        }
    }, 1500);
    setProvider(provider);
    return monitor;
};

export const setupProvider = (provider: BaseProvider, setActivity: (activity: 'active' | 'inactive') => void): providers.Provider => {
    provider.pollingInterval = 15_000;
    const _poll = provider.poll;
    provider.poll = async function (...args) {
        if (!userIsViewingPage()) {
            setActivity('inactive');
            return;
        }
        setActivity('active');
        return _poll.apply(this, args);
    };

    return provider;
};

/**
 * @dev we were getting inconsistent results using provider.waitForTransaction with the websocket provider, so this function extends the functionality of waitForTransaction
 * and attempted to grab the transaction receipt after expected expectedBlocktime for [n] network. It attempts to get this tx result until it is mined.
 * @param provider Ethers provider
 * @param txHash Transaction to poll
 */
export const waitForTransaction = async (chainId: number, provider: Provider, txHash: string) => {
    const expectedBlocktime = NETWORKS[chainId].expectedBlocktime;
    const TIMEOUT = expectedBlocktime * 1000;

    const awaitTx = async (): Promise<TransactionReceipt> => {
        const confirmationsRequired = expectedBlocktime < 10 ? 2 : 1;
        try {
            const receipt = await provider.waitForTransaction(txHash, confirmationsRequired, TIMEOUT * 1.5);
            return receipt;
        } catch (e) {
            console.debug('🚨 transaction results took longer than expected');
            const receipt = await provider.getTransactionReceipt(txHash);
            if (receipt && receipt.confirmations >= confirmationsRequired) {
                return receipt;
            } else {
                return await awaitTx();
            }
        }
    };

    return await awaitTx();
};

export const transactionUri = (txHash: string, networkConfig: NetworkConfig) => `${networkConfig.blockExplorer}tx/${txHash}`;

export const addressUri = (address: EthAddress, networkConfig: NetworkConfig) => `${networkConfig?.blockExplorer}address/${address}`;
