import BullaBankerArtifact from '@bulla-network/contracts/artifacts/contracts/BullaBanker.sol/BullaBanker.json';
import bullaBankerModuleArtifact from '@bulla-network/contracts/artifacts/contracts/BullaBankerModule.sol/BullaBankerModule.json';
import BullaClaimERC721Artifact from '@bulla-network/contracts/artifacts/contracts/BullaClaimERC721.sol/BullaClaimERC721.json';
import BullaFinanceArtifact from '@bulla-network/contracts/artifacts/contracts/BullaFinance.sol/BullaFinance.json';
import BullaInstantPaymentArtifact from '@bulla-network/contracts/artifacts/contracts/BullaInstantPayment.sol/BullaInstantPayment.json';
import BatchCreateArtifact from '@bulla-network/contracts/artifacts/contracts/BatchCreate.sol/BatchCreate.json';
import BullaManagerArtifact from '@bulla-network/contracts/artifacts/contracts/BullaManager.sol/BullaManager.json';
import FrendLendArtifact from '@bulla-network/contracts/artifacts/contracts/FrendLend.sol/FrendLend.json';
import IBullaClaimArtifact from '@bulla-network/contracts/artifacts/contracts/interfaces/IBullaClaim.sol/IBullaClaim.json';
import { BatchCreate } from '@bulla-network/contracts/typechain/BatchCreate';
import { BullaBanker, BullaBankerInterface } from '@bulla-network/contracts/typechain/BullaBanker';
import { BullaBankerModule, BullaBankerModuleInterface } from '@bulla-network/contracts/typechain/BullaBankerModule';
import { BullaClaimERC721, BullaClaimERC721Interface } from '@bulla-network/contracts/typechain/BullaClaimERC721';
import { BullaFinance, BullaFinanceInterface } from '@bulla-network/contracts/typechain/BullaFinance';
import { BullaInstantPayment, BullaInstantPaymentInterface } from '@bulla-network/contracts/typechain/BullaInstantPayment';
import { BullaManager, BullaManagerInterface } from '@bulla-network/contracts/typechain/BullaManager';
import { ERC20, ERC20Interface } from '@bulla-network/contracts/typechain/ERC20';
import { ERC721Interface } from '@bulla-network/contracts/typechain/ERC721';
import { FrendLend, FrendLendInterface } from '@bulla-network/contracts/typechain/FrendLend';
import { IBullaClaimInterface } from '@bulla-network/contracts/typechain/IBullaClaim';
import { IERC721Interface } from '@bulla-network/contracts/typechain/IERC721';
import { Contract, utils } from 'ethers';
import { abi as ERC20ABI } from '../../../artifacts/@openzeppelin/contracts/token/ERC20/ERC20.sol/ERC20.json';
import ERC721Artifact from '../../../artifacts/@openzeppelin/contracts/token/ERC721/ERC721.sol/ERC721.json';
import IERC721Artifact from '../../../artifacts/@openzeppelin/contracts/token/ERC721/IERC721.sol/IERC721.json';
import GnosisSafe_1_1Artifact from '../../../artifacts/contracts/GnosisSafe.sol/GnosisSafe_1_1.json';
import GnosisSafe_1_2Artifact from '../../../artifacts/contracts/GnosisSafe.sol/GnosisSafe_1_2.json';
import GnosisSafe_1_3Artifact from '../../../artifacts/contracts/GnosisSafe.sol/GnosisSafe_1_3.json';
import Multicall2Artifact from '../../../artifacts/contracts/Multicall2.sol/Multicall2.json';
import { abi as PosterABI } from '../../../artifacts/contracts/Poster.sol/Poster.json';
import ReceivableFactoringPoolABI from '../../../artifacts/contracts/ReceivableFactoringPool.sol/ReceivableFactoringPool.json';
import BullaFactoringABIV2 from '../../../artifacts/contracts/BullaFactoring.sol/BullaFactoringV2.json';
import BullaFactoringABIV1 from '../../../artifacts/contracts/BullaFactoring.sol/BullaFactoringV1.json';
import BullaFactoringPermissionsABI from '../../../artifacts/contracts/Permissions.sol/Permissions.json';
import BaseFeeManagerABI from '../../../artifacts/contracts/BaseFeeManager.sol/BaseFeeManager.json';
import { GnosisSafe as GnosisSafe_1_1, GnosisSafeInterface as GnosisSafe_1_1Interface } from '../../../artifacts/typechain/GnosisSafe_1_1';
import { GnosisSafe as GnosisSafe_1_2, GnosisSafeInterface as GnosisSafe_1_2Interface } from '../../../artifacts/typechain/GnosisSafe_1_2';
import { GnosisSafe as GnosisSafe_1_3, GnosisSafeInterface as GnosisSafe_1_3Interface } from '../../../artifacts/typechain/GnosisSafe_1_3';
import { Multicall2 } from '../../../artifacts/typechain/Multicall2';
import { PosterInterface } from '../../../artifacts/typechain/Poster';
import { ReceivableFactoringPoolInterface, ReceivableFactoringPool } from '../../../artifacts/typechain/ReceivableFactoringPool';
import {
    BullaFactoringInterface as BullaFactoringInterfaceV2,
    BullaFactoring as BullaFactoringV2,
} from '../../../artifacts/typechain/BullaFactoring';
import {
    BullaFactoringInterface as BullaFactoringInterfaceV1,
    BullaFactoring as BullaFactoringV1,
} from '../../../artifacts/typechain/BullaFactoringV1';
import { PermissionsInterface, Permissions } from '../../../artifacts/typechain/Permissions';

import { BaseFeeManagerInterface, BaseFeeManager } from '../../../artifacts/typechain/BaseFeeManager';
import { EthAddress } from '../ethereum';

export const IBullaFinance = new utils.Interface(BullaFinanceArtifact.abi) as BullaFinanceInterface;
export const getBullaFinanceContract = (address: EthAddress) => new Contract(address, BullaFinanceArtifact.abi) as BullaFinance;

export const IFrendLend = new utils.Interface(FrendLendArtifact.abi) as FrendLendInterface;
export const getFrendLendContract = (address: EthAddress) => new Contract(address, IFrendLend) as FrendLend;

export const I_IBullaClaim = new utils.Interface(IBullaClaimArtifact.abi) as IBullaClaimInterface;

export const IBullaClaimERC721 = new utils.Interface(BullaClaimERC721Artifact.abi) as BullaClaimERC721Interface;
export const getBullaClaimContract = (address: EthAddress) => new Contract(address, BullaClaimERC721Artifact.abi) as BullaClaimERC721;

export const I_IERC721 = new utils.Interface(IERC721Artifact.abi) as IERC721Interface;
export const IERC721 = new utils.Interface(ERC721Artifact.abi) as ERC721Interface;
export const getBatchCreateContract = (address: EthAddress) => new Contract(address, BatchCreateArtifact.abi) as BatchCreate;

export const IBullaBanker = new utils.Interface(BullaBankerArtifact.abi) as BullaBankerInterface;
export const getBullaBankerContract = (address: EthAddress) => new Contract(address, BullaBankerArtifact.abi) as BullaBanker;

export const IBullaModule = new utils.Interface(bullaBankerModuleArtifact.abi) as BullaBankerModuleInterface;
export const getBullaBankerModuleContract = (address: EthAddress) =>
    new Contract(address, bullaBankerModuleArtifact.abi) as BullaBankerModule;

export const IBullaInstantPay = new utils.Interface(BullaInstantPaymentArtifact.abi) as BullaInstantPaymentInterface;
export const getBullaInstantPaymentContract = (address: EthAddress) =>
    new Contract(address, BullaInstantPaymentArtifact.abi) as BullaInstantPayment;

export const IBullaManagerInterface = new utils.Interface(BullaManagerArtifact.abi) as BullaManagerInterface;
export const getBullaManagerContract = (address: EthAddress) => new Contract(address, BullaManagerArtifact.abi) as BullaManager;

export const IPoster = new utils.Interface(PosterABI) as PosterInterface;

export const IERC20 = new utils.Interface(ERC20ABI) as ERC20Interface;
export const getERC20Contract = (address: EthAddress) => new Contract(address, ERC20ABI) as ERC20;

export const IGnosisSafe_1_1 = new utils.Interface(GnosisSafe_1_1Artifact.abi) as GnosisSafe_1_1Interface;
export const getGnosisSafe_1_1Contract = (address: EthAddress) => new Contract(address, GnosisSafe_1_1Artifact.abi) as GnosisSafe_1_1;

export const IGnosisSafe_1_2 = new utils.Interface(GnosisSafe_1_2Artifact.abi) as GnosisSafe_1_2Interface;
export const getGnosisSafe_1_2Contract = (address: EthAddress) => new Contract(address, GnosisSafe_1_2Artifact.abi) as GnosisSafe_1_2;

export const IGnosisSafe_1_3 = new utils.Interface(GnosisSafe_1_3Artifact.abi) as GnosisSafe_1_3Interface;
export const getGnosisSafe_1_3Contract = (address: EthAddress) => new Contract(address, GnosisSafe_1_3Artifact.abi) as GnosisSafe_1_3;

export const getMultiCallContract = (address: EthAddress) => new Contract(address, Multicall2Artifact.abi) as Multicall2;

export const IReceivableFactoringPool = new utils.Interface(ReceivableFactoringPoolABI) as ReceivableFactoringPoolInterface;
export const getReceivableFactoringPoolContract = (address: EthAddress) =>
    new Contract(address, ReceivableFactoringPoolABI) as ReceivableFactoringPool;

export const IBullaFactoringV1 = new utils.Interface(BullaFactoringABIV1) as BullaFactoringInterfaceV1;
export const getBullaFactoringContractV1 = (address: EthAddress) => new Contract(address, BullaFactoringABIV1) as BullaFactoringV1;

export const IBullaFactoringV2 = new utils.Interface(BullaFactoringABIV2) as BullaFactoringInterfaceV2;
export const getBullaFactoringContractV2 = (address: EthAddress) => new Contract(address, BullaFactoringABIV2) as BullaFactoringV2;

export const IPermissions = new utils.Interface(BullaFactoringPermissionsABI) as PermissionsInterface;
export const getBullaFactoringPermissionsContract = (address: EthAddress) =>
    new Contract(address, BullaFactoringPermissionsABI) as Permissions;

export const IBaseFeeManager = new utils.Interface(BaseFeeManagerABI) as BaseFeeManagerInterface;
export const getFeeManagerContract = (address: EthAddress) => new Contract(address, BaseFeeManagerABI) as BaseFeeManager;
