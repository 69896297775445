import { Box, Container, Spinner } from '@chakra-ui/react';
import { BaseProvider, ExternalProvider } from '@ethersproject/providers';
import { useSafeAppsSDK } from '@gnosis.pm/safe-apps-react-sdk';
import { SecretType } from '@venly/connect';
import { Venly } from '@venly/web3-provider';
import { EIP1193Provider } from '@web3-onboard/core';
import gnosisModule from '@web3-onboard/gnosis';
import injectedModule from '@web3-onboard/injected-wallets';
import { InjectedNameSpace, InjectedWalletModule } from '@web3-onboard/injected-wallets/dist/types';
import { init, useConnectWallet, useSetChain } from '@web3-onboard/react';
import walletConnectModule from '@web3-onboard/walletconnect';
import web3authModule from '@web3-onboard/web3auth';
import { ethers, getDefaultProvider, providers, Signer } from 'ethers';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import VenlyLogo from 'url:../assets/venly.svg';
import { toChecksumAddress } from '../data-lib/ethereum';
import { allNetworks, ChainId, chainIds, NetworkConfig, NETWORKS, setupProvider, SUPPORTED_NETWORKS } from '../data-lib/networks';
import { OnboardPage } from '../pages/onboard/onboard';
import { isUserReady, useAuth } from '../state/auth-state';
import { inIframe, IN_DEV_ENV, reloadWindow } from '../tools/common';
import { setLocalStorage } from '../tools/localStorage';
import { clearSessionStorage, STORAGE_KEYS } from '../tools/storage';
import { usePrevious } from './usePrevious';
import { useLocalStorage } from './useStorage';

type NetworkConnectionState = 'not-connected' | NetworkConfig | 'unsupported-network';
const isReady = (state: NetworkConnectionState): state is NetworkConfig => state !== 'not-connected' && state !== 'unsupported-network';

type InnerWeb3Context = {
    userAddress: string;
    mainnetProvider: BaseProvider;
    provider: providers.Provider;
    signerProvider: providers.Provider;
    providersByChainId: Record<number, providers.Provider>;
    signer: Signer;
    connectedNetworkConfigUnsafe: NetworkConnectionState;
    icon: string | undefined;
    attemptedToConnect: boolean;
};

type Web3Context = InnerWeb3Context & {
    connectedNetworkConfig: NetworkConfig;
    connectedNetwork: ChainId;
};

type OnboardContext = {
    connectedWalletName: string | undefined;
    walletSelect: () => Promise<boolean | void>;
    switchWallet: () => Promise<boolean | void>;
    changeNetwork: (chainId: ChainId) => Promise<void | boolean>;
    resetOnboard: () => void;
};

const Web3Context = React.createContext<Web3Context | undefined>(undefined);
const OnboardContext = React.createContext<OnboardContext | undefined>(undefined);

const networks = allNetworks.map(({ chainId, name: label, nativeCurrency, connections: { rpcUrl } }) => ({
    id: toHexString(chainId),
    label,
    token: nativeCurrency.symbol,
    rpcUrl,
}));

const venly: InjectedWalletModule = {
    label: 'Venly',
    injectedNamespace: InjectedNameSpace.Ethereum,
    checkProviderIdentity: ({ provider }: { provider: any }) => true,
    getInterface: async () => {
        const providerEngine = await Venly.createProviderEngine({
            clientId: IN_DEV_ENV ? 'Testaccount' : 'BullaNetwork',
            skipAuthentication: false,
            pollingInterval: 10000,
            secretType: SecretType.MATIC,
            environment: IN_DEV_ENV ? 'staging' : 'production',
        });
        return {
            provider: providerEngine as unknown as EIP1193Provider,
        };
    },
    getIcon: async () => (await fetch(VenlyLogo)).text(),
    platforms: ['desktop'],
};

const web3auth = web3authModule({
    clientId: 'BGn2dtS0KcIhka6b6-6UrSLX_3mcGZSOY3HBo65ZowJAcTDsGq9KJgDOEZfDT8VrU2QkOgUIRNIp5Ncr_4_yBgo',
    authMode: 'DAPP',
    enableLogging: true,
    web3AuthNetwork: 'testnet',
    storageKey: 'local',
    uiConfig: { web3AuthNetwork: 'testnet', displayErrorsOnModal: true },
});

const defaultWallets = [injectedModule({ custom: [] }), walletConnectModule({ projectId: 'e2440fd0fb72d16ccecbd4648731db8b' })];
const _ = init({
    chains: networks,
    wallets: inIframe
        ? [
              gnosisModule({
                  whitelistedDomains: [
                      /^https:\/\/gnosis-safe\.io$/,
                      /^https:\/\/app\.safe\.global$/,
                      /^https:\/\/safe\.global$/,
                      /^https:\/\/safe\.fuse\.io$/,
                      /^https:\/\/staging\-safe\.fuse\.io$/,
                  ],
              }),
          ]
        : defaultWallets,
    accountCenter: { desktop: { enabled: false }, mobile: { enabled: false } },
});

const mainnetProvider = getDefaultProvider();

const Web3Provider = ({ children: app }: { children: React.ReactNode }) => {
    const [attemptedToConnect, setAttemptedToConnect] = useState(false);
    const [previousWallet, setPreviousWallet] = useLocalStorage<string>(STORAGE_KEYS.selectedWallet);
    const { safe, connected } = useSafeAppsSDK();
    const [{ wallet }, connect] = useConnectWallet();
    const [{ connectedChain }, setChain] = useSetChain();
    const connectedAccount = wallet?.accounts[0];
    const walletName = wallet?.label;
    const walletAddress = !!connectedAccount?.address ? toChecksumAddress(connectedAccount?.address) : '';
    const isConnectedToSafe = safe.safeAddress !== '' && connected;
    const userAddress = isConnectedToSafe && inIframe ? safe.safeAddress : walletAddress;
    const walletProvider = wallet?.provider;
    const icon = wallet?.icon;
    const [inactivityCounter, setInactivityCounter] = React.useState(0);

    const connectedNetworkConfigUnsafe = (() => {
        if (connectedChain === null) return 'not-connected';
        const networkId = parseInt(connectedChain.id, 16) as ChainId;
        if (!SUPPORTED_NETWORKS.includes(networkId)) return 'unsupported-network';
        return NETWORKS[networkId];
    })();

    const autoConnect = () =>
        connect({
            autoSelect: inIframe
                ? { label: 'Gnosis Safe', disableModals: true }
                : !!previousWallet
                ? { label: previousWallet, disableModals: true }
                : undefined,
        });

    useEffect(() => {
        if (!!walletName && walletName !== previousWallet && walletName !== 'Gnosis Safe') {
            setPreviousWallet(walletName);
        }
    }, [walletName]);

    useEffect(() => {
        (async function () {
            if (!!previousWallet && !inIframe) {
                if (!!window.ethereum) {
                    const provider = new ethers.providers.Web3Provider(window.ethereum);
                    // there's a bug on firefox that causes provider.listAccounts to take forever
                    setTimeout(() => setAttemptedToConnect(true), 2000);
                    const addresses = await provider.listAccounts();

                    if (addresses.length !== 0) await autoConnect();
                }
            } else if (inIframe) await autoConnect();

            setAttemptedToConnect(true);
        })();
    }, []);

    const resetOnboard = () => {
        if (walletName === 'WalletConnect') setLocalStorage('walletconnect', '');
        setLocalStorage(STORAGE_KEYS.selectedWallet, '');
        window.location.reload();
    };

    const providersByChainId = useMemo(
        () =>
            allNetworks
                .map(({ chainId, connections: { rpcUrl } }): [number, BaseProvider] => [
                    chainId,
                    new ethers.providers.JsonRpcProvider(rpcUrl, chainId),
                ])
                .reduce<Record<number, BaseProvider>>((acc, [chainId, provider]) => ({ ...acc, [chainId]: provider }), {}),
        [],
    );

    const setActivity = (activity: 'active' | 'inactive') => {
        activity == 'inactive' ? setInactivityCounter(current => current + 1) : setInactivityCounter(0);
    };

    useEffect(() => {
        if (inactivityCounter === 120) {
            setInactivityCounter(0);
            resetOnboard();
        }
    }, [inactivityCounter === 120]); // Poll is 15 seconds, 15 * 4 = 1 min, 30 mins = 120

    const provider = useMemo(
        () =>
            !isReady(connectedNetworkConfigUnsafe)
                ? ({} as providers.Provider)
                : setupProvider(providersByChainId[connectedNetworkConfigUnsafe.chainId], setActivity),
        [connectedNetworkConfigUnsafe],
    );

    const signerProvider = useMemo(
        () =>
            !walletProvider || !isReady(connectedNetworkConfigUnsafe)
                ? ({} as providers.Provider)
                : new ethers.providers.Web3Provider(walletProvider as ExternalProvider, connectedNetworkConfigUnsafe.chainId),

        [walletProvider, connectedNetworkConfigUnsafe],
    );

    const signer = useMemo(
        () =>
            !walletProvider || !isReady(connectedNetworkConfigUnsafe)
                ? ({} as Signer)
                : new ethers.providers.Web3Provider(walletProvider as ExternalProvider, connectedNetworkConfigUnsafe.chainId).getSigner(),

        [walletProvider, connectedNetworkConfigUnsafe],
    );

    const web3Context: Web3Context = useMemo(() => {
        return {
            userAddress,
            provider,
            mainnetProvider,
            signer,
            connectedNetworkConfigUnsafe,
            connectedNetworkConfig: connectedNetworkConfigUnsafe as NetworkConfig,
            connectedNetwork: (connectedNetworkConfigUnsafe as NetworkConfig)?.chainId,
            icon,
            providersByChainId,
            attemptedToConnect,
            signerProvider,
        };
    }, [userAddress, provider, signer, connectedNetworkConfigUnsafe, attemptedToConnect, icon]);

    const onboardContext: OnboardContext = useMemo(
        () => ({
            walletSelect: () => connect({}),
            switchWallet: () => connect({}),
            resetOnboard,
            changeNetwork: (chainId: number) => setChain({ chainId: toHexString(chainId) }),
            connectedWalletName: walletName,
        }),
        [wallet, connectedChain, setChain],
    );

    return (
        <>
            <Web3Context.Provider value={web3Context}>
                <OnboardContext.Provider value={onboardContext}>{app}</OnboardContext.Provider>
            </Web3Context.Provider>
        </>
    );
};

const useWeb3 = () => {
    const context = useContext(Web3Context);
    if (!context) throw new Error('useWeb3 must be used within the Web3Provider');
    return context;
};

export const useWeb3Unsafe = (): Partial<InnerWeb3Context> => {
    const context = useContext(Web3Context);
    if (!context) throw new Error('useWeb3 must be used within the Web3Provider');
    return context as InnerWeb3Context;
};

const useOnboard = () => {
    const context = useContext(OnboardContext);
    if (!context) throw new Error('useOnboard must be used within the Web3Provider');
    return {
        connectedWalletName: context.connectedWalletName,
        walletSelect: context.walletSelect,
        switchWallet: context.switchWallet,
        walletReset: context.resetOnboard,
        changeNetwork: context.changeNetwork,
    };
};

export { Web3Provider, useWeb3, useOnboard };

function toHexString(chainId: number): string {
    return `0x${chainId.toString(16)}`;
}

export const OnboardRouter = ({ children }: { children: React.ReactNode }) => {
    const { userAddress, connectedNetworkConfigUnsafe } = useWeb3Unsafe();
    const {
        safe: { safeAddress },
        connected: inSafeApp,
    } = useSafeAppsSDK();
    const { attemptedToConnect } = useWeb3();
    const { user } = useAuth();
    const navigate = useNavigate();
    const previousUserAddress = usePrevious(userAddress);

    const appReady =
        attemptedToConnect &&
        !!userAddress &&
        !!connectedNetworkConfigUnsafe &&
        isReady(connectedNetworkConfigUnsafe) &&
        (!!safeAddress || !inIframe) && //if app is loaded in iframe, assume we are in a gnosis-safe and await a connection via the SDK
        isUserReady(user);

    const appWasPreviouslyReady = usePrevious(appReady);

    //** if app has loaded and the provider changes (network), refresh */
    useEffect(() => {
        if (appReady) {
            if (window.location.hash === '#/onboard') {
                navigate('/');
            } else if (appWasPreviouslyReady && !inSafeApp && previousUserAddress !== userAddress) {
                // if network or user address changes, but not on first
                reloadWindow();
                clearSessionStorage();
            }
        }
    }, [connectedNetworkConfigUnsafe, userAddress]);

    return (
        <Routes>
            {appReady && <Route path="/*" element={children} />}
            {!appReady && <Route path="/onboard" element={<OnboardPage />} />}
            {attemptedToConnect && !appReady && <Route path="*" element={<Navigate replace to="/onboard" />} />}
            {appReady && <Route path="/onboard" element={<Navigate replace to="/" />} />}
            {!attemptedToConnect && !appReady && (
                <Route
                    path="*"
                    element={
                        <Container w="fit-content" h="100vh" display={'table'}>
                            <Box w="fit-content" display={'table-cell'} verticalAlign="middle">
                                <Spinner my="auto" color="black" />
                            </Box>
                        </Container>
                    }
                />
            )}
        </Routes>
    );
};
